import qs from "qs"
import { call, put, takeLatest } from 'redux-saga/effects'

import http from "../services/http";
import {
    GetRoles,
    getRolesFailure,
    getRolesSuccess,
    types as rolesTypes
} from "../actions/roles";

function* getRoles(action: GetRoles) {
    try {
        const response = yield call(http, `/roles?${qs.stringify({userIds: action.userIds})}`);
        yield put(getRolesSuccess(response.data));
    } catch (e) {
        yield put(getRolesFailure());
    }
}

function* rolesSaga() {
    yield takeLatest(rolesTypes.GET_ROLES, getRoles);
}

export default rolesSaga;
