import { call, put, takeLatest, delay } from 'redux-saga/effects'

import http from "../services/http";
import {redirectTo} from "../stores/store";
import {
    Login,
    loginSuccess,
    loginFailure,
    types as authTypes
} from "../actions/auth";

function* handleLogin(action: Login) {
    try {
        const response = yield call(http, "/auth/login", {
            method: "POST",
            body: { ...action.user }
        });
        const { token, user, role } = response.data;
        yield put(loginSuccess(token, user, role));
    } catch (e) {
        yield put(loginFailure());
    }
}

function* redirectAfterSuccess() {
    yield delay(1000);
    yield call(redirectTo, "/admin")
}

function* redirectToLogin() {
    yield call(redirectTo, "/login")
}


function* authSaga() {
    yield takeLatest(authTypes.LOGIN, handleLogin);
    yield takeLatest(authTypes.LOGOUT, redirectToLogin);
    yield takeLatest(authTypes.LOGIN_SUCCESS, redirectAfterSuccess);
}

export default authSaga;
