import React from "react";
import TitleSkeleton from "../../atoms/TitleSkeleton";
import TextLineSkeleton from "../../atoms/TextLineSkeleton";

const ArticleSkeleton: React.FC = () => (
    <>
        <TitleSkeleton/>
        <TextLineSkeleton/>
        <TextLineSkeleton/>
        <TextLineSkeleton/>
        <TextLineSkeleton/>
        <TextLineSkeleton/>
    </>
);

export default ArticleSkeleton;
