import React from "react";
import {Link} from "react-router-dom";
import Markdown from "react-markdown";

import "./index.scss"
import {getHttpPath} from "../../../../services/http";
// Elements
import HighlightedCode from "../../../molecules/HighlightedCode";
import {markdownHtmlParser} from "../../../organisms/MarkdownArticle";
import {humanFormatFullDate} from "../../../../services/datetime";
import OthersFooter from "../../../molecules/OthersFooter";
import PdfEmbed from "../../../molecules/PdfEmbed";

const OmrArticlePage: React.FC = () =>  {
    const text = `
# Optical music recognition

## My take on deciphering a musical score

As part of a project for school, we were given the opportunity to choose our own projects.
It had to do something with computer vision, as it was a \`computer vision\` course, but we had no other
restriction than that.

So I thought... It would be pretty cool to develop an algorithm, that could read musical scores and translate 
them to midi files ! Let's do that, deadline is in 5 days, it's more than enough ! 🎵

It was pretty interesting how throughout the project, the hardest part was not necessarily to write an algorithm that 
would work, but to keep a clean, understandable and reusable code base. Don't get me wrong, the image analysis was far 
from easy, but it was quite fun to see my algorithms perform better and better as I improved them. Keeping the code clean
however was quite hard, as I was working in a \`Jupyter Notebook\`. This is quite handy to experiment things, but when
it comes to making the code more complex, it can get very tricky and make refactoring very frustrating.

After spending two days developing features for the algorithm, I spend a day and a half refactoring the code, in order
to be able to move on to the next step... 🤦‍♂️ Writing clean code is important, and should not be neglected ! Had 
I had the time, I probably would have written some unit tests for my image pre-processing functions. The fact that I 
had to clean up a bug that was present from the start, but that I never noticed, on day 4/5 was a tough one, that could 
have been avoided with a very simple test, that would have taken 2 minutes to write.

So there you go, my algorithm is not perfect, but here are the results of my research if you want to have a look ! 
As you can see at the end of the paper, its results are not perfect, but it's a good start I believe, maybe I'll
improve it in the future !

Ciao 👋
    `;
    const date = new Date("2020-04-17T12:41:08+02:00");
    return (
        <div className="container">
            <div className="column">
                <Link className="button is-fullwidth is-success is-outlined" to="/others" style={{marginBottom: '1rem'}}>
                    <span className="icon">
                        <i className="fas fa-arrow-left"/>
                    </span>
                    <span>Back to "Others"</span>
                </Link>
            </div>
            <div className="card is-info">
                <div className="columns" style={{ padding: "1rem" }}>
                    <div className="column">
                        <PdfEmbed url={getHttpPath("/articles/pdf/omr_paper.pdf")}/>
                    </div>
                    <div className="column">
                        <div className="card-content">
                            <div className="content content-of-card">
                                <Markdown
                                    source={text}
                                    escapeHtml={false}
                                    astPlugins={[markdownHtmlParser]}
                                    renderers={{
                                        code: HighlightedCode,
                                    }}/>
                                <p className="right-aligned">
                                    <code ><time dateTime={date.toLocaleString()} >{humanFormatFullDate(date)}</time></code>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OthersFooter id="omr-article"/>
        </div>
    );
};


export default OmrArticlePage;
