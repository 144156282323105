import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

type Props = {
    value?: string;
    language?: string;
}

const HighlightedCode: React.FC<Props> = ({ value, language }) => value ? (
    <SyntaxHighlighter language={language || "typescript"} style={darcula}>
        {value}
    </SyntaxHighlighter>
    ) : null
;

export default HighlightedCode;
