import { call, put, takeLatest, delay } from 'redux-saga/effects'

import http from "../services/http";
import {redirectTo} from "../stores/store";
import {
    CreateUser,
    createUserFailure,
    createUserSuccess,
    getUsersFailure,
    getUsersSuccess,
    types as userTypes
} from "../actions/users";

function* createUser(action: CreateUser) {
    try {
        yield call(http, "/user", {
            method: "POST",
            body: { ...action.payload }
        });
        yield delay(500);
        yield put(createUserSuccess());
    } catch (e) {
        yield put(createUserFailure());
    }
}

function* redirectAfterSuccess() {
    yield delay(1000);
    yield call(redirectTo, "/blog")
}

function* getUsers() {
    try {
        const response = yield call(http, "/user/all");
        yield put(getUsersSuccess(response.data));
    } catch (e) {
        yield put(getUsersFailure());
    }
}


function* usersSaga() {
    yield takeLatest(userTypes.CREATE_USER, createUser);
    yield takeLatest(userTypes.CREATE_USER_SUCCESS, redirectAfterSuccess);
    yield takeLatest(userTypes.GET_USERS, getUsers);
}

export default usersSaga;
