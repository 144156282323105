import * as Yup from 'yup';
import {LoginContents} from "../../../actions/auth";

export const getYupValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('E-mail is not valid!')
      .required('E-mail is required!'),
    password: Yup.string().required('Password is required!'),
  });
};

export const getErrorsFromValidationError = (validationError: any) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const _validate = (
  getValidationSchema: (
    values: LoginContents,
  ) => Yup.ObjectSchema<LoginContents>,
) => {
  return (values: LoginContents) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

export const validate = _validate(getYupValidationSchema);
