import {Role, User} from 'commons';

import { types } from '../actions/auth';


export type AuthState = {
  isLoading: boolean;
  hasErrored: boolean;
  token?: string;
  currentUser?: User;
  currentUserRole?: Role;
};

const initialState = {
  isLoading: false,
  hasErrored: false
};

const authReducer = (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        isLoading: true,
        hasErrored: false,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        isLoading: false,
        hasErrored: false,
        token: action.payload.token,
        currentUser: action.payload.user,
        currentUserRole: action.payload.role,
      };
    case types.LOGOUT:
      return {
        isLoading: false,
        hasErrored: false,
      };
    default:
      return state;
  }
};

export default authReducer;
