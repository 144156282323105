import { call, put, takeLatest, delay } from 'redux-saga/effects'
import {
    getArticlesFailure,
    getArticlesSuccess,
    SubmitArticle,
    submitArticleFailure,
    submitArticleSuccess,
    types as articlesTypes
} from "../actions/articles";
import {
    DeleteArticle,
    GetArticle,
    getArticleFailure,
    getArticleSuccess,
    types as articleTypes, UpdateArticle, updateArticleFailure,
    updateArticleSuccess
} from "../actions/article";
import http from "../services/http";
import {redirectTo} from "../stores/store";

function* submitArticle(action: SubmitArticle) {
    try {
        yield call(http, "/articles", {
            method: "POST",
            body: { ...action.payload }
        });
        yield delay(500);
        yield put(submitArticleSuccess());
    } catch (e) {
        yield put(submitArticleFailure());
    }
}

function* redirectAfterSuccess() {
    yield delay(1000);
    yield call(redirectTo, "/blog")
}

function* getArticles() {
    try {
        const response = yield call(http, "/articles");
        yield put(getArticlesSuccess(response.data));
    } catch (e) {
        yield put(getArticlesFailure());
    }
}

function* getArticle(action: GetArticle) {
    try {
        const response = yield call(http, `/articles/${action.id}`);
        yield delay(500);
        yield put(getArticleSuccess(response.data));
    } catch (e) {
        yield put(getArticleFailure());
    }
}

function* updateArticle(action: UpdateArticle) {
    try {
        const response = yield call(http, `/articles`, { method: "PUT", body: action.article });
        yield delay(500);
        yield put(updateArticleSuccess(response.data));
    } catch (e) {
        yield put(updateArticleFailure());
    }
}

function* deleteArticle(action: DeleteArticle) {
    try {
        const response = yield call(http, `/articles/${action.id}`, { method: "DELETE" });
        yield delay(500);
        yield put(updateArticleSuccess(response.data));
    } catch (e) {
        yield put(updateArticleFailure());
    }
}

function* articleSaga() {
    yield takeLatest(articleTypes.GET_ARTICLE, getArticle);
    yield takeLatest(articleTypes.DELETE_ARTICLE, deleteArticle);
    yield takeLatest(articleTypes.UPDATE_ARTICLE, updateArticle);
    yield takeLatest(articleTypes.UPDATE_ARTICLE_SUCCESS, redirectAfterSuccess);
    yield takeLatest(articlesTypes.SUBMIT_ARTICLE, submitArticle);
    yield takeLatest(articlesTypes.SUBMIT_ARTICLE_SUCCESS, redirectAfterSuccess);
    yield takeLatest(articlesTypes.GET_ARTICLES, getArticles);
}

export default articleSaga;
