const makeRange = (multiples: number, max: number) => {
    var list = [];
    for (var i = 0; i <= max; i+=multiples) {
        list.push(i);
    }
    return list
}

const firstAndLastAddress = (address: number[], cidr: number) => {

    const bit_mask = `${"1".repeat(cidr)}${"0".repeat(32-cidr)}`.match(/.{1,8}/g);
    if (bit_mask == null) {
        throw Error("Bit mask is null")
    }
    const mask = bit_mask.map(bit => parseInt(bit, 2))
    const mask_index = mask.findIndex(num => num === 0)
    const index = mask_index === -1 ? mask.length - 1 : mask_index-1
    
    const octet_significatif = mask[index]
    const magic_number = 256 - octet_significatif
    
    const address_oct_sign = address[index]
    
    const range = makeRange(magic_number, 256)
    const first_index = range.findIndex(num => num > address_oct_sign) - 1
    const first_sign = range[first_index]
    const second_sign = range[first_index + 1] - 1
    
    const first = address.map((val, i) => {
        if(i < index) {
            return val
        }
        if (i === index) {
            return first_sign
        }
        return 0
    }).join(".")
    const last = address.map((val, i) => {
        if(i < index) {
            return val
        }
        if (i === index) {
            return second_sign
        }
        return 255
    }).join(".")

    return {
        first,
        last,
        mask: mask.join(".")
    }
}

export default firstAndLastAddress
