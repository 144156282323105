import { Role } from 'commons';
import { types as rolesTypes } from '../actions/roles';

export type RolesByUser = {
    [key: string]: {
        role: Role;
        user_id: string;
    }
}

export type RolesState = {
    roles?: RolesByUser;
    loading: boolean;
    error: boolean;
};

const initialState = {
    loading: false,
    error: false,
};

const rolesReducer = (state: RolesState = initialState, action: any): RolesState => {
    switch (action.type) {
        case rolesTypes.GET_ROLES:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case rolesTypes.GET_ROLES_FAILURE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case rolesTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.roles,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};

export default rolesReducer;
