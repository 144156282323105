import React, {useEffect} from "react";
import {connect} from "react-redux";
import {State} from "../../../reducers";
import {selectCurrentUser, selectCurrentUserRole} from "../../../selectors/auth";
import {Role, Roles, User} from "commons";
import {getRoles} from "../../../actions/roles";

type Props = {
    allowedRoles: Roles[];
    currentUser?: User;
    currentUserRole?: Role;

    getRoles: typeof getRoles;
}
const PermissionFilter: React.FC<Props> = ({
   allowedRoles,
   currentUserRole,
   currentUser,
   getRoles,
   children
}) => {
    useEffect(() => {
        if (currentUser && !currentUserRole) {
            getRoles([currentUser.id])
        }
    }, [getRoles, currentUserRole, currentUser]);

    if (currentUserRole && (allowedRoles.includes(currentUserRole.role_name) || currentUserRole.role_name === Roles.SuperUser)) {
        return <>{children}</>;
    }
    return null
};

const mapStateToProps = (state: State) => ({
    currentUserRole: selectCurrentUserRole(state),
    currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = {
    getRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionFilter);
