import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Article } from "commons";
import {Link, RouteComponentProps} from "react-router-dom";

import "./index.scss"
import { State } from "../../../../reducers";
// Elements
import CardSkeleton from "../../../molecules/CardSkeleton";
import MarkdownArticle from "../../../organisms/MarkdownArticle";
// Selectors
import { selectArticleById } from "../../../../selectors/articles";
// Actions
import { getArticle } from "../../../../actions/article";

type Props = {
    getArticle: typeof getArticle;
    isLoading: boolean;
    hasErrored: boolean;
    article?: Article;
} & RouteComponentProps<{id: string}>

const ReadArticlePage: React.FC<Props> = ({
  article,
  match,
  getArticle,
  hasErrored,
  isLoading
}) => {
    useEffect(() => {
        // Update the document title using the browser API
        if (article) {
            document.title = `${article.text.substr(2, 25)}...`;
        }
    }, [article]);

    useEffect(() => {
        if (!article) {
            getArticle(match.params.id);
        }
    }, [getArticle, article, match.params.id]);

    return (
        <div className="container">
            <Link className="button is-success is-outlined" to="/blog" id="back-btn">
                <span className="icon">
                    <i className="fas fa-arrow-left"/>
                </span>
                <span>Back to blog</span>
            </Link>
            {
                article && !hasErrored && !isLoading ? (
                    <MarkdownArticle
                        id={article.id}
                        text={article.text}
                        img_url={article.img_url}
                        date={article.date}
                    />
                ) : <CardSkeleton/>
            }
        </div>
    )
};

const mapStateToProps = (state: State, ownProps: Props) => ({
    isLoading: state.articles.loading,
    hasErrored: state.articles.error,
    article: selectArticleById(state, ownProps.match.params.id)
});

const mapDispatchToProps = {
    getArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadArticlePage);
