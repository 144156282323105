import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./index.scss";

import logo from "../../../assets/images/Logo/LogoWhite.png";
import { State } from "../../../reducers";
import { isLoggedIn } from "../../../selectors/auth";
import { logout } from "../../../actions/auth";

type OwnProps = {
    title: string;
    subtitle: string;
}
type ConnectedProps = {
    isLoggedIn: boolean;
    logout: typeof logout;
}

type Props = OwnProps & ConnectedProps;

const HomeTitle: React.FunctionComponent<Props>  = ({title, subtitle, isLoggedIn, logout}) => (
        <div className="has-text-centered hero-main-sup">
            <div className="hero-body">
                <div className="is-flex-desktop" id="header-flex">
                    <div className="container">
                        <h2 className="subtitle">
                            <Link to="/" className="plain-colored-link">
                                <img className="image is-64x64" src={logo} id="spin-logo" alt="Logo"/>
                            </Link>
                        </h2>
                        <h1 className="title is-family-secondary">
                            <Link to="/" className="plain-colored-link">{title}</Link>
                        </h1>
                        <h2 className="subtitle">
                            <Link to="/">{subtitle}</Link>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
);

const mapStateToProps = (state: State) => ({
    isLoggedIn: isLoggedIn(state)
});

const mapDispatchToProps = {
    logout: logout
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeTitle);
