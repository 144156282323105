import React, {useEffect} from "react";
import ReactGA from "react-ga";
import { Article } from "commons";
import "./index.scss"

import {State} from "../../../reducers";
import {selectArticles} from "../../../selectors/articles";
import {getArticles} from "../../../actions/articles";
import {connect} from "react-redux";
import MarkdownArticle from "../../organisms/MarkdownArticle";
// import CardSkeleton from "../../molecules/CardSkeleton";

type Props = {
    articles?: Article[];
    isLoading: boolean;
    hasErrored: boolean;
    getArticles: typeof getArticles;
}

const Blog: React.FC<Props> = ({
    isLoading,
    articles,
    hasErrored,
    getArticles,
}) => {
    useEffect(() => {
        // Update the document title using the browser API
        document.title = `Lotus Gears`;
    });
    // useEffect(() => {
    //     getArticles();
    // }, [getArticles]);

    useEffect(() => ReactGA.pageview('/blog'));

    const text = `# 🚧 Maintenance 🚧
Since Heroku has changed its hosting policy, this website's backend is broken.
It now requires a paid plan in order to properly function.

Since I will not be paying for this legacy site I built when I was a student,
some functionality is currently lost. I might restore it at some point in the
future, but for now it will stay broken for a while.

You can still access all of this site's static content, especially the *"Higher
Cookery"* game in the **Games** section !

See you around,

Pierre
`

    return (
        <div className="container md-render">
            {/* {isLoading && !articles &&  <>
                <CardSkeleton imageOnLeft/>
                <br />
                <CardSkeleton imageOnRight/>
            </>}
            {hasErrored && <div className="notification is-danger">
                Something went wrong while fetching the articles. Please try again later !
            </div>} */}
            <div key="maintenance">
                <MarkdownArticle
                    id="maintenance"
                    text={text}
                    side={true}
                    img_url="https://images.pexels.com/photos/224924/pexels-photo-224924.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                />
                <br />
            </div>


            {/* {
                articles && articles.map((article, index) => (
                    <div key={article.id}>
                        <MarkdownArticle
                            id={article.id}
                            text={article.text}
                            side={index % 2 === 1}
                            img_url={article.img_url}
                            date={article.date}
                        />
                        <br />
                    </div>
                ))
            } */}
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    articles: selectArticles(state),
    isLoading: state.articles.loading,
    hasErrored: state.articles.error,
});

const mapDispatchToProps = {
    getArticles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
