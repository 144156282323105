import axios, { Method } from 'axios';

import { getToken } from './auth';

// This handles both the newer nest js error object structure and the old bespoke one for bc purposes.
const getErrorMessage = (errorJson: any) => {
  if (!errorJson || !errorJson.error) {
    return undefined;
  }

  // Older koa error
  if (typeof errorJson.error === 'string') {
    return errorJson.message || errorJson.error;
  }

  // Newer nest error
  if (errorJson.details && errorJson.details.length > 0) {
    // If we have details, use the first error message we find
    // This is more detailed than the generic message below
    const error = errorJson.details.find((r: any) => !r.isValid);
    if (error) {
      return error.errors[0].message;
    }
  }
  return errorJson.error.message;
};

export function getAxiosInstance() {
  const token = getToken();
  return axios.create({
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  });
}

type APIOptions = {
  method?: Method;
  body?: any;
};

export function getHttpPath(path: string): string {
  return `${process.env.REACT_APP_BACK_URL}${path}`;
}

async function http(path: string, options: APIOptions = {}) {
  const url = getHttpPath(path);
  const sendingOptions = options.body
    ? { ...options, data: options.body }
    : { ...options };

  try {
    const response = await getAxiosInstance()({
      url,
      ...sendingOptions,
    });
    // @ts-ignore
    if (!response.err || !response.error) {
      return response;
    }
    const errorMessage = getErrorMessage(response);
    if (errorMessage) {
      throw Error(errorMessage);
    } else {
      throw new Error('Could not get error from server response');
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export default http;
