import React, {useEffect} from "react";
import ReactGA from "react-ga";
import {Link} from "react-router-dom";

import "./index.scss"

const NotFound: React.FC = () => {
    useEffect(() => ReactGA.pageview('/404'));

    return (
        <div className="column is-half is-offset-one-quarter">
            <div className="notification is-danger has-text-centered">
                <h1 className="title is-1">404</h1>
                <h1 className="title is-2 is-family-secondary">Nope, nothing here !</h1>
                <h1 className="title is-2 is-family-secondary">¯\_(ツ)_/¯</h1>
                <Link to="/blog" className="button is-success">Take me back home</Link>
            </div>
        </div>
    );
}

export default NotFound;
