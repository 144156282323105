import { State } from '../reducers';
import { isTokenExpired } from '../services/auth';

export const getToken = (state: State) => state.auth && state.auth.token;
export const isLoggedIn = (state: State): boolean => {
  const token = getToken(state);
  return !!(token && !isTokenExpired(token));
};

export const selectCurrentUser = (state: State) => state.auth && state.auth.currentUser;

export const selectCurrentUserRole = (state: State) => state.auth && state.auth.currentUserRole;
