import React from "react";

type Props = {
    title: string;
    subtitle: string;
    specialClasses: string;
}

const Hero: React.FC<Props>  = ({title, subtitle, specialClasses}) => (
    <section className={`hero is-info ${specialClasses}`}>
        <div className="hero-body">
            <div className="container">
                <h1 className="title">
                    {title}
                </h1>
                <h2 className="subtitle">
                    {subtitle}
                </h2>
            </div>
        </div>
    </section>
);

export default Hero;
