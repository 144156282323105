import {Link} from "react-router-dom";
import React from "react";
import PermissionFilter from "../../wrappers/PermissionFilter";
import {Roles} from "commons";

const AdminMenu: React.FC = () => (
    <aside className="menu is-hidden-mobile">
        <p className="menu-label">
            General
        </p>
        <ul className="menu-list">
            <li><Link to="/admin">Dashboard</Link></li>
        </ul>

        <PermissionFilter allowedRoles={[Roles.SuperUser]}>
            <p className="menu-label">
                Users
            </p>
            <ul className="menu-list">
                <li><Link to="/admin/users">View Users</Link></li>
                <li><Link to="/admin/users/new">Add a user</Link></li>
            </ul>
        </PermissionFilter>

        <p className="menu-label">
            Articles
        </p>
        <ul className="menu-list">
            <li><Link to="/admin/articles">View Articles</Link></li>
            <li><Link to="/admin/articles/new">Write an article</Link></li>
        </ul>
    </aside>
);

export default AdminMenu;
