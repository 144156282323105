import React from "react";
import { Link } from "react-router-dom";

type Props = {
    name: string;
    onClick: () => void;
    isActive?: boolean;
}

const Tab: React.FC<Props> = ({name, isActive, onClick}) => (
    <li id={name.toLowerCase()} className={isActive ? "is-active" : ""} onClick={onClick}>
        <Link to={`/${name.toLowerCase()}`} className={isActive ? "has-background-white has-text-info" : ""}>{name}</Link>
    </li>
);

export default Tab;
