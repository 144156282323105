import React, {useEffect} from "react";

import './index.scss'
import {connect} from "react-redux";
import {State} from "../../../reducers";
import {selectArticles} from "../../../selectors/articles";
import {Article, Roles, User} from "commons";
// Actions
import {getArticles} from "../../../actions/articles";
import {getUsers} from "../../../actions/users";
// Selectors
import {selectUsers} from "../../../selectors/users";
import PermissionFilter from "../../wrappers/PermissionFilter";

type Props = {
    articles?: Article[];
    isLoadingArticles: boolean;
    hasErroredArticles: boolean;

    users?: User[];
    isLoadingUsers: boolean;
    hasErroredUsers: boolean;

    getArticles: typeof getArticles;
    getUsers: typeof getUsers;
}
const InfoTiles: React.FC<Props> = ({
    articles,
    isLoadingArticles,
    hasErroredArticles,
    users,
    isLoadingUsers,
    hasErroredUsers,
    getArticles,
    getUsers
}) => {
    useEffect(() => {
        getArticles();
    }, [getArticles]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    return (
        <section className="info-tiles">
            <div className="tile is-ancestor has-text-centered">
                <PermissionFilter allowedRoles={[Roles.SuperUser]}>
                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            <p className="title">{users ? users.length : "Zero"}</p>
                            <p className="subtitle">Users</p>
                        </article>
                    </div>
                </PermissionFilter>
                <div className="tile is-parent">
                    <article className="tile is-child box">
                        <p className="title">{articles ? articles.length : "Zero"}</p>
                        <p className="subtitle">Articles</p>
                    </article>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state: State) => ({
    articles: selectArticles(state),
    isLoadingArticles: state.articles.loading,
    hasErroredArticles: state.articles.error,

    users: selectUsers(state),
    isLoadingUsers: state.users.loading,
    hasErroredUsers: state.users.error,
});

const mapDispatchToProps = {
    getArticles,
    getUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTiles);
