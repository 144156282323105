import { User } from 'commons';
import { types as userTypes } from '../actions/users';

export type UsersState = {
    users?: User[];
    loading: boolean;
    error: boolean;
};

const initialState = {
    loading: false,
    error: false,
};

const usersReducer = (state: UsersState = initialState, action: any): UsersState => {
    switch (action.type) {
        case userTypes.GET_USERS:
        case userTypes.CREATE_USER:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case userTypes.GET_USERS_FAILURE:
        case userTypes.CREATE_USER_FAILURE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case userTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case userTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.users,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};

export default usersReducer;
