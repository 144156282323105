import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// @ts-ignore
import AdBlockDetect from 'react-ad-block-detect';
import ReactGA from "react-ga";
import './index.scss';

import routes from './routes';
import * as serviceWorker from './serviceWorker';
import store_contents from './stores/store';
import AdBlockModal from "./elements/molecules/AdBlockModal";

if (process.env.NODE_ENV === "production") {
    ReactGA.initialize("UA-164277952-1");
}
ReactDOM.render(
    <Provider store={store_contents.store}>
        <PersistGate loading={null} persistor={store_contents.persistor}>
            {routes}
            <AdBlockDetect>
                <AdBlockModal/>
            </AdBlockDetect>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
