import { Action, ActionCreator } from 'redux';
import {Role, User} from 'commons';

export const types = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
};

export type LoginContents = {
  email: string;
  password: string;
};

export interface Login extends Action {
  type: String;
  user: LoginContents;
}
export interface LoginSuccess extends Action {
  type: String;
  payload: {
    token: string;
    user: User;
    role: Role;
  };
}
export interface LoginFailure extends Action {
  type: String;
}

export interface Logout extends Action {
  type: String;
}

export const login: ActionCreator<Login> = (user: LoginContents) => ({
  type: types.LOGIN,
  user,
});
export const loginSuccess: ActionCreator<LoginSuccess> = (token: string, user: User, role: Role) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    token,
    user,
    role
  },
});
export const loginFailure: ActionCreator<LoginFailure> = () => ({
  type: types.LOGIN_FAILURE,
});

export const logout: ActionCreator<Logout> = () => ({
  type: types.LOGOUT,
});
