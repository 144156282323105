import React from "react";
import { Link } from "react-router-dom";

type Props = {
    title?: string;
    image?: string;
    url?: string;
    imageOnRight?: boolean;
    imageOnLeft?: boolean;
}

const Card: React.FC<Props> = ({image, url, imageOnRight, imageOnLeft, children}) => (
    <div className="card is-info">
        <div className="columns" style={{ padding: "1rem" }}>
            {
                (image && imageOnLeft) ? 
                <div className="column">
                    <Link to={url || image} className="image">
                        <img src={image} alt={url} />
                    </Link>
                </div> : null
            }
            <div className="column" style={{width: "100%"}}>
                <div className={`card-content ${imageOnRight || imageOnLeft ? "is-paddingless" : ""}`}>
                    <div className="content content-of-card">
                        {children}
                    </div>
                </div>
            </div>
            {
                (image && imageOnRight) ? 
                <div className="column">
                    <Link to={url || image} className="image">
                        <img src={image} alt={url} />
                    </Link>
                </div> : null
            }
        </div>
    </div>
);

export default Card;
