import * as Yup from 'yup';

import { FormContents } from './index';
import {Roles} from "commons";

const MIN_PASSWORD_LENGTH = 6;
const MAX_NAME_LENGTH = 32;
const MIN_NAME_LENGTH = 2;

const getYupValidationSchema = (values: FormContents) => {
  return Yup.object().shape({
    email: Yup.string()
        .email('E-mail is not valid!')
        .required('E-mail is required!'),
    firstName: Yup.string()
        .max(
            MAX_NAME_LENGTH,
            `First name has to be shorter than ${MAX_NAME_LENGTH} characters!`,
        )
        .min(
            MIN_NAME_LENGTH,
            `First name has to be longer than ${MIN_NAME_LENGTH} characters!`,
        )
        .required('First name is required!'),
    lastName: Yup.string()
        .max(
            MAX_NAME_LENGTH,
            `Last name has to be shorter than ${MAX_NAME_LENGTH} characters!`,
        )
        .min(
            MIN_NAME_LENGTH,
            `Last name has to be longer than ${MIN_NAME_LENGTH} characters!`,
        )
        .required('Last name is required!'),
    password: Yup.string()
        .min(
            MIN_PASSWORD_LENGTH,
            `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`,
        )
        .required('Password is required!'),
    confirmPassword: Yup.string()
        .oneOf([values.password], 'Passwords are not the same!')
        .required('Password confirmation is required!'),
    role: Yup.string()
        .oneOf(Object.values(Roles), 'Invalid role!')
        .required('Please choose a role!'),
  });
};


export const getErrorsFromValidationError = (validationError: any) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const _validate = (
  getValidationSchema: (
    values: FormContents,
  ) => Yup.ObjectSchema<FormContents>,
) => {
  return (values: FormContents) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

export const validate = _validate(getYupValidationSchema);
