import jwt_decode from 'jwt-decode';

import { State } from '../reducers';
import store_contents from '../stores/store';

export function getToken(): string | undefined {
  const appState: State = store_contents.store.getState();
  return appState.auth ? appState.auth.token : undefined;
}

export function isLoggedIn(): boolean {
  const token = getToken();
  return !!(token && !isTokenExpired(token));
}

export function getTokenExpirationDate(token: string) {
  const decoded: { exp: number } = jwt_decode(token);
  if (!decoded.exp) return null;

  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token?: string) {
  if (!token) {
    token = getToken();
  }
  if (!token) {
    return true;
  }

  const date = getTokenExpirationDate(token);
  if (!date) {
    return false;
  } else {
    return !(date.valueOf() > new Date().valueOf());
  }
}
