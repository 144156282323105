import { Action, ActionCreator } from 'redux';
import {Roles, User} from "commons"

export const types = {
    CREATE_USER: 'CREATE_USER',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

    GET_USERS: 'GET_USERS',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',
};

export interface CreateUser extends Action {
    type: String;
    payload: {
        user: Omit<User, "id">;
        role: Roles;
    };
}
export interface CreateUserSuccess extends Action {
    type: String;
}
export interface CreateUserFailure extends Action {
    type: String;
}

export interface GetUsers extends Action {
    type: String;
}
export interface GetUsersSuccess extends Action {
    type: String;
    users: User[];
}
export interface GetUsersFailure extends Action {
    type: String;
}

export const createUser: ActionCreator<CreateUser> = (user: User, role: Roles) => ({
    type: types.CREATE_USER,
    payload: {
        user,
        role
    },
});
export const createUserSuccess: ActionCreator<CreateUserSuccess> = () => ({
    type: types.CREATE_USER_SUCCESS,
});
export const createUserFailure: ActionCreator<CreateUserFailure> = () => ({
    type: types.CREATE_USER_FAILURE,
});


export const getUsers: ActionCreator<GetUsers> = () => ({
    type: types.GET_USERS,
});
export const getUsersSuccess: ActionCreator<GetUsersSuccess> = (users: User[]) => ({
    type: types.GET_USERS_SUCCESS,
    users,
});
export const getUsersFailure: ActionCreator<GetUsersFailure> = () => ({
    type: types.GET_USERS_FAILURE,
});
