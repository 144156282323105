import React from "react";

type Props = {
    url: string;
}

const PdfEmbed: React.FC<Props> = ({ url }) => (
    <>
        <div className="is-hidden-touch column">
            <embed
                src={url}
                type="application/pdf"
                style={{width: "100%", height: "100%", minHeight: "50rem"}}
            />
        </div>
        <div className="is-hidden-desktop has-text-centered">
            <button onClick={() => window.open(url)} className="button is-success">
                <span>View PDF</span>
                <span className="icon">
                    <i className="fas fa-file-pdf"/>
                </span>
            </button>
        </div>
    </>
);

export default PdfEmbed
