import React, { useState } from "react";
import {withRouter, RouteComponentProps} from 'react-router-dom';

// Elements
import HomeTitle from "../../atoms/HomeTitle";
import Tab from "../../atoms/Tab";
import {State} from "../../../reducers";
import {isLoggedIn} from "../../../selectors/auth";
import {connect} from "react-redux";

import "./index.scss";

type ConnectedProps = {
    isLoggedIn: boolean;
}

const Header: React.FC<RouteComponentProps & ConnectedProps> = props => {
    const tabs = ["Blog", "Games", "Others", "Admin"];

    const [activeTab, setActiveTab] = useState(
        tabs.map(tab => tab.toLowerCase()).indexOf(props.location.pathname.split("/")[1])
    );
    return (
        <section className="hero is-info " id="hero-main">
            <HomeTitle title="Lotus Gears" subtitle="Dev work, all kinds of experiences that I believe are worth sharing :)"/>
            
            <div className="hero-foot hero-main-sup">
                <nav className="tabs is-centered is-boxed is-size-5	">
                    <ul>
                        {tabs.map((tab, index) => (
                            <Tab name={tab} isActive={index===activeTab} onClick={() => setActiveTab(index)} key={index}/>
                        ))}
                    </ul>
                </nav>
            </div>
        </section>
    )
};

const mapStateToProps = (state: State) => ({
    isLoggedIn: isLoggedIn(state)
});

export default connect(mapStateToProps, null)(
    withRouter(Header)
);
