import React from "react";
import { User, Roles } from "commons";
import {ErrorMessage, Field, Formik} from "formik";

import {validate} from "./_formik";
// Organisms
import AdminWrapper from "../../../wrappers/AdminWrapper";
// Atoms
import Hero from "../../../atoms/Hero";
import Subtitle from "../../../atoms/Subtitle";
// Actions
import {createUser} from "../../../../actions/users";
import {State} from "../../../../reducers";
import {connect} from "react-redux";

export type FormContents = {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    role: string
};

type Props = {
    createUser: typeof createUser;
    isLoading: boolean;
    hasErrored: boolean;
}

const NewUserPage: React.FC<Props> = ({createUser, isLoading, hasErrored}) => {
    const initial_values = { email: '', password: '', confirmPassword: '', firstName: '', lastName: '', role: Roles.User };

    const allowedRoles = Object.keys(Roles)
        .filter(role => role !== "SuperUser")
        .reverse(); // Reverse so that 'User' is default value

    const onSubmit = (values: FormContents) => {
        const user: Omit<User, "id"> = {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
        };
        createUser(user, values.role)
    };
    return (
        <AdminWrapper>
            <Hero
                title="Users"
                subtitle="Adding a new user 👋"
                specialClasses="is-bold is-small"
            />
            <div className="columns add-user-form">
                <div className="column is-6">
                    <Subtitle text="Fill out the form below"/>
                    <Formik
                        initialValues={initial_values}
                        validate={validate}
                        onSubmit={onSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleSubmit,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="field">
                                    <label className="label">User information</label>
                                    <div className="control has-icons-left">
                                        <Field
                                            className={
                                                errors.email && touched.email
                                                    ? 'input is-danger'
                                                    : 'input'
                                            }
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                        />
                                        <span className="icon is-small is-left">
                                          <i className="fas fa-envelope" />
                                        </span>
                                        <ErrorMessage
                                            name="email"
                                            component="p"
                                            className="help is-danger"
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <Field
                                            className={
                                                errors.firstName && touched.firstName
                                                    ? 'input is-danger'
                                                    : 'input'
                                            }
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name"
                                        />
                                        <span className="icon is-small is-left">
                                          <i className="fas fa-user" />
                                        </span>
                                        <ErrorMessage
                                            name="firstName"
                                            component="p"
                                            className="help is-danger"
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <Field
                                            className={
                                                errors.lastName && touched.lastName
                                                    ? 'input is-danger'
                                                    : 'input'
                                            }
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name"
                                        />
                                        <span className="icon is-small is-left">
                                          <i className="fas fa-user" />
                                        </span>
                                        <ErrorMessage
                                            name="lastName"
                                            component="p"
                                            className="help is-danger"
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Password</label>
                                    <div className="control has-icons-left">
                                        <Field
                                            className={
                                                errors.password && touched.password
                                                    ? 'input is-danger'
                                                    : 'input'
                                            }
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                        />
                                        <span className="icon is-small is-left">
                                          <i className="fas fa-shield-alt" />
                                        </span>
                                        <ErrorMessage
                                            name="password"
                                            component="p"
                                            className="help is-danger"
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control has-icons-left">
                                        <Field
                                            className={
                                                errors.confirmPassword && touched.confirmPassword
                                                    ? 'input is-danger'
                                                    : 'input'
                                            }
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm the password"
                                        />
                                        <span className="icon is-small is-left">
                                          <i className="fas fa-shield-alt" />
                                        </span>
                                        <ErrorMessage
                                            name="confirmPassword"
                                            component="p"
                                            className="help is-danger"
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Role</label>
                                    <div className="control has-icons-left">
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-scroll"/>
                                        </span>
                                        <div className="select is-fullwidth">
                                            <Field
                                                className={
                                                    errors.role && touched.role
                                                        ? 'is-danger'
                                                        : ''
                                                }
                                                as="select"
                                                name="role"
                                            >
                                                {allowedRoles.map(role => (
                                                    <option value={role.toUpperCase()}>{role}</option>
                                                ))}
                                            </Field>
                                        </div>

                                        <ErrorMessage
                                            name="role"
                                            component="p"
                                            className="help is-danger"
                                        />
                                    </div>
                                </div>

                                <button
                                    className={`button is-block is-info ${isLoading ? 'is-loading' : ''}`}
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </form>
                        )}
                    </Formik>
                </div>
                <div className="column is-4 is-hidden-touch">
                    <iframe src="https://giphy.com/embed/l0MYC0LajbaPoEADu" width="480" height="277" frameBorder="0"
                            className="giphy-embed" title="Giphy Embed" allowFullScreen/>
                </div>
            </div>
            {
                hasErrored && <div className="notification is-danger">
                    Something went wrong while creating the user. Please try again later !
                </div>
            }
        </AdminWrapper>
    );
};

const mapStateToProps = (state: State) => ({
    isLoading: state.users.loading,
    hasErrored: state.users.error,
});

const mapDispatchToProps = {
    createUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserPage);
