import { all, fork } from 'redux-saga/effects';

import articleSaga from "./articles";
import userSaga from "./users";
import rolesSaga from "./roles";
import authSaga from "./auth";
import analyticsSaga from "./analytics";
import shortenSaga from "./shorten";

function* rootSaga() {
    yield all([
        fork(analyticsSaga),
        fork(articleSaga),
        fork(authSaga),
        fork(rolesSaga),
        fork(shortenSaga),
        fork(userSaga),
    ]);
}

export default rootSaga;
