import React, {useEffect, useState} from "react";
import "./index.scss";
import ReactGA from "react-ga";

const AdBlockModal = () => {
    const [isActive, setActive] = useState(true);
    useEffect(() => ReactGA.modalview('/ad-block'));

    return (
        <div className={`modal ${isActive ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <article className="message is-danger">
                    <div className="message-header">
                        Hey there !
                        <button className="delete" onClick={() => setActive(false)}/>
                    </div>
                    <div className="message-body">
                        <p>
                            It seems you're using an <strong>Ad-Blocker</strong>. Fortunately for you, we do not use any ads on this website !
                            It's not a problem at all, so you can safely close this modal and keep on browsing :)
                            <br/>
                            <br/>Cheers 🍻
                        </p>
                        <figure className="image is-fullwidth is-flex" style={{justifyContent: 'center'}}>
                            <img alt="Adblock Logo" src="https://getadblock.com/images/updateAssets/core_logo_full.svg" style={{width: '50%'}}/>
                        </figure>
                    </div>
                </article>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => setActive(false)}/>
        </div>
    );
};

export default AdBlockModal;
