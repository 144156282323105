import {Action, ActionCreator} from "redux";
import {Article} from "commons";


export const types = {
    GET_ARTICLE: 'GET_ARTICLE',
    GET_ARTICLE_SUCCESS: 'GET_ARTICLE_SUCCESS',
    GET_ARTICLE_FAILURE: 'GET_ARTICLE_FAILURE',

    UPDATE_ARTICLE: 'UPDATE_ARTICLE',
    UPDATE_ARTICLE_SUCCESS: 'UPDATE_ARTICLE_SUCCESS',
    UPDATE_ARTICLE_FAILURE: 'UPDATE_ARTICLE_FAILURE',

    DELETE_ARTICLE: 'DELETE_ARTICLE',
};

export interface GetArticle extends Action {
    type: String;
    id: string;
}
export interface GetArticleSuccess extends Action {
    type: String;
    article: Article;
}
export interface GetArticleFailure extends Action {
    type: String;
}


export interface UpdateArticle extends Action {
    type: String;
    article: Article;
}
export interface UpdateArticleSuccess extends Action {
    type: String;
    article: Article;
}
export interface UpdateArticleFailure extends Action {
    type: String;
}

export interface DeleteArticle extends Action {
    type: String;
    id: string;
}

export const getArticle: ActionCreator<GetArticle> = (id: string) => ({
    type: types.GET_ARTICLE,
    id,
});
export const getArticleSuccess: ActionCreator<GetArticleSuccess> = (article: Article) => ({
    type: types.GET_ARTICLE_SUCCESS,
    article,
});
export const getArticleFailure: ActionCreator<GetArticleFailure> = () => ({
    type: types.GET_ARTICLE_FAILURE,
});


export const updateArticle: ActionCreator<UpdateArticle> = (article: Article) => ({
    type: types.UPDATE_ARTICLE,
    article,
});
export const updateArticleSuccess: ActionCreator<UpdateArticleSuccess> = (article: Article) => ({
    type: types.UPDATE_ARTICLE_SUCCESS,
    article,
});
export const updateArticleFailure: ActionCreator<UpdateArticleFailure> = () => ({
    type: types.UPDATE_ARTICLE_FAILURE,
});


export const deleteArticle: ActionCreator<DeleteArticle> = (id: string) => ({
    type: types.DELETE_ARTICLE,
    id,
});
