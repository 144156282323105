import React from "react";
import "./index.scss";

const BigLoader: React.FC = () => (
    <div className="loader-box"> 
        <div className="loader-container">
            <svg viewBox="-2000 -1000 4000 2000">
                <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"></path>
                <use href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px"></use>
            </svg>
        </div>
    </div>
)

export default BigLoader;