import React, {useEffect} from 'react';
import ReactGA from "react-ga";
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import { ErrorMessage, Field, Formik } from 'formik';

import { login, LoginContents } from '../../../actions/auth';

import { validate } from './_formik';

import './Login.scss';
import {State} from "../../../reducers";
import {getToken} from "../../../selectors/auth";
import {isTokenExpired} from "../../../services/auth";

const logo = require('../../../assets/images/Logo/IconPrimary.png');

type LoginProps = {
  login: typeof login;

  token?: string;
  isLoading: boolean;
  hasErrored: boolean;
};

const Login: React.FC<LoginProps>  = ({
    login,
    isLoading,
    hasErrored,
    token
}) => {
    useEffect(() => ReactGA.pageview('/login'));

    const initial_values = {email: '', password: ''};
    const onSubmit = (values: LoginContents) => {
        login(values);
    };
    if (!isTokenExpired(token)) {
      return <Redirect push to="/admin"/>;
    } else {
      return (
          <section id="login-page" className="hero is-fullheight">
            <div className="hero-body">
              <div className="container has-text-centered">
                <div className="column is-4 is-offset-4">
                  <h3 className="title has-text-white">Login</h3>
                  <p className="subtitle has-text-white">
                    Please login to proceed.
                  </p>
                  <div className="box">
                    <figure className="avatar">
                      <img src={logo} alt="Logo Lotus Gears"/>
                    </figure>
                    <Formik
                        initialValues={initial_values}
                        validate={validate}
                        onSubmit={onSubmit}
                    >
                      {({
                          values,
                          errors,
                          touched,
                          handleSubmit,
                          /* and other goodies */
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="field">
                              <div className="control has-icons-left">
                                <Field
                                    className={
                                      errors.email && touched.email
                                          ? 'input is-large is-danger'
                                          : 'input is-large'
                                    }
                                    type="email"
                                    name="email"
                                    placeholder="Your Email"
                                />
                                <span className="icon is-small is-left">
                                <i className="fas fa-envelope"/>
                              </span>
                                <ErrorMessage
                                    name="email"
                                    component="p"
                                    className="help is-danger"
                                />
                              </div>
                            </div>
                            <div className="field">
                              <div className="control has-icons-left">
                                <Field
                                    className={
                                      errors.password && touched.password
                                          ? 'input is-large is-danger'
                                          : 'input is-large'
                                    }
                                    type="password"
                                    name="password"
                                    placeholder="Your Password"
                                />
                                <span className="icon is-small is-left">
                                <i className="fas fa-shield-alt"/>
                              </span>
                                <ErrorMessage
                                    name="password"
                                    component="p"
                                    className="help is-danger"
                                />
                              </div>
                            </div>
                            {hasErrored && (
                                <div className="notification is-danger">
                                  <strong>Failed :</strong> Incorrect email/password
                                </div>
                            )}
                            <button
                                className={`button is-block is-info is-large is-fullwidth ${isLoading ? 'is-loading' : ''}`}
                                type="submit"
                                disabled={isLoading}
                            >
                              Login
                            </button>
                          </form>
                      )}
                    </Formik>
                  </div>
                  <Link to="/blog" className="has-text-white">
                    &nbsp;·&nbsp; I don't have an account, go back &nbsp;·&nbsp;
                  </Link>
                </div>
              </div>
            </div>
          </section>
      );
    }
};

const masStateToProps = (state: State) => ({
  token: getToken(state),
  isLoading: state.auth.isLoading,
  hasErrored: state.auth.hasErrored,
});

const mapDispatchToProps = {
  login,
};

export default connect(
  masStateToProps,
  mapDispatchToProps,
)(Login);
