import { Action, ActionCreator } from 'redux';

export const types = {
    SHORTEN: 'SHORTEN',
    SHORTEN_SUCCESS: 'SHORTEN_SUCCESS',
    SHORTEN_FAILURE: 'SHORTEN_FAILURE',
};

export interface Shorten extends Action {
    type: String;
    payload: {
        url: string
    };
}
export interface ShortenSuccess extends Action {
    type: String;
    payload: {
        url: string;
    };
}
export interface ShortenFailure extends Action {
    type: String;
}

export const shorten: ActionCreator<Shorten> = (url: string) => ({
    type: types.SHORTEN,
    payload: {
        url
    }
});
export const shortenSuccess: ActionCreator<ShortenSuccess> = (url: string) => ({
    type: types.SHORTEN_SUCCESS,
    payload: {
        url,
    },
});
export const shortenFailure: ActionCreator<ShortenFailure> = () => ({
    type: types.SHORTEN_FAILURE,
});
