import {takeLatest, call, put} from 'redux-saga/effects'

import {Shorten, shortenFailure, shortenSuccess, types as shortenTypes} from "../actions/shorten";
import http from "../services/http";

function* shortenUrl(action: Shorten) {
    try {
        const response = yield call(http, "/shorten", {
            method: "POST",
            body: { url: action.payload.url }
        });
        yield put(shortenSuccess(response.data.url));
    } catch (e) {
        yield put(shortenFailure());
    }
}

function* shortenSaga() {
    yield takeLatest(shortenTypes.SHORTEN, shortenUrl);
}

export default shortenSaga;
