import React, { useState } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';

type Props = {
    id: string;
};

const OthersFooter: React.FC<Props> = ({ id }) => {
    const [clickedCopy, setClickedCopy] = useState(false);
    const [clickedLike, setClickedLike] = useState(false);
    const [copyButtonRef, setCopyButtonRef] = useState();
    const [likeButtonRef, setLikeButtonRef] = useState();

    return (
        <div className="card">
            <footer className="card-footer">
                <div className="card-footer-item">
                    <CopyToClipboard text={`${process.env.REACT_APP_URL}/others/${id}`}>
                        <button
                            className={`button is-fullwidth ${clickedCopy ? 'is-success' : 'is-success is-outlined'}`}
                            onClick={() => {
                                setClickedCopy(!clickedCopy);
                                copyButtonRef.blur();
                            }}
                            ref={setCopyButtonRef}
                        >
                            <span className="icon">
                                <i className={`fas ${clickedCopy ? 'fa-check' : 'fa-share-square'}`}/>
                            </span>
                            <span>{clickedCopy ? 'Done !' : 'Copy link'}</span>
                        </button>
                    </CopyToClipboard>
                </div>

                <div className="card-footer-item">
                    <button
                        className={`button is-fullwidth ${clickedLike ? 'is-success' : 'is-success is-outlined'}`}
                        onClick={() => {
                            setClickedLike(!clickedLike);
                            likeButtonRef.blur()
                        }}
                        ref={setLikeButtonRef}
                    >
                        <span className="icon">
                            <i className="far fa-thumbs-up"/>
                        </span>
                        <span>Like this Article</span>
                    </button>
                </div>
            </footer>
        </div>
    )
};

export default OthersFooter;
