import React, {useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";

import Title from "../../../atoms/Title";
import Subtitle from "../../../atoms/Subtitle";
import {shorten} from "../../../../actions/shorten";
import {State} from "../../../../reducers";
import {selectShortUrl} from "../../../../selectors/shorten";

type Props = {
    shortUrl?: string;
    isLoading: boolean;
    hasErrored: boolean;

    shorten: typeof shorten;
}

const UrlShorten: React.FC<Props> = ({
    shorten,
    shortUrl,
    isLoading,
    hasErrored,
 }) => {
    const [url, setUrl] = useState();
    console.log({shortUrl})
    return (
        <div className="container">
            <Link className="button is-success is-outlined" to="/others">
                <span className="icon">
                    <i className="fas fa-arrow-left"/>
                </span>
                <span>Back to "Others"</span>
            </Link>
            <div className="card is-info">
                <div className="columns" style={{ padding: "1rem" }}>
                    <div className="column">
                        <Title text="Shorten your URLs"/>
                        <Subtitle text="...thanks to YOURLS !"/>
                        <p>
                            You can shorten your urls lo look like {" "}
                            <a href="https://sh.lotusgears.me/l9e33">https://sh.lotusgears.me/l9e33</a>.
                            <br/>
                            This one for example redirects to the <code>Blog</code> section.
                            <br/>
                            <br/>
                        </p>
                        <form className="field has-addons" onSubmit={(e) => {
                            e.preventDefault();
                            shorten(url);
                        }}>
                            <div className="control is-expanded">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="https://url.to.shorten"
                                    value={url}
                                    onChange={event => setUrl(event.target.value)}
                                />
                            </div>
                            <div className="control">
                                <button className={`button is-info ${isLoading ? 'is-loading' : ''}`} onClick={() => shorten(url)}>
                                    Shorten !
                                </button>
                            </div>
                        </form>

                        { shortUrl &&
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <a className="input is-primary" href={shortUrl}>
                                        {shortUrl}
                                    </a>
                                </div>
                                <div className="control">
                                    <CopyToClipboard text={shortUrl}>
                                        <p className="button is-primary">
                                            <span className="icon">
                                                <i className="fas fa-share-square"/>
                                            </span>
                                            <span>Copy link</span>
                                        </p>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        }
                        {
                            hasErrored && <div className="notification is-danger">
                                Something went wrong shortening the URL. Please try again later !
                            </div>
                        }
                    </div>
                    <div className="column">
                        <a href="https://yourls.org/">
                            <figure className="image">
                                <img src="https://yourls.org/images/yourls-logo.svg" alt="Yourls"/>
                            </figure>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const masStateToProps = (state: State) => ({
    shortUrl: selectShortUrl(state),
    isLoading: state.shorten.loading,
    hasErrored: state.shorten.error,
});

const mapDispatchToProps = {
    shorten,
};

export default connect(masStateToProps, mapDispatchToProps)(UrlShorten);
