import AdminMenu from "../../molecules/AdminMenu";
import React from "react";

const AdminWrapper = ({ children }: any) => (
    <div className="container">
        <div className="columns">
            <div className="column is-3">
                <AdminMenu />
            </div>
            <div className="column is-9">
                {children}
            </div>
        </div>
    </div>
);

export default AdminWrapper;
