import React, { useState } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { State } from "../../../reducers";
import { isLoggedIn } from "../../../selectors/auth";
import PermissionFilter from "../../wrappers/PermissionFilter";
import {Roles} from "commons";

type Props = {
    id: string;
    isLoggedIn: boolean;
};

const ArticleFooter: React.FC<Props> = ({ id, isLoggedIn }) => {
    const [clickedCopy, setClickedCopy] = useState(false);
    const [clickedLike, setClickedLike] = useState(false);
    const [copyButtonRef, setCopyButtonRef] = useState();
    const [likeButtonRef, setLikeButtonRef] = useState();

    return (
        <div className="card">
            <footer className="card-footer">
                <div className="card-footer-item">
                    <CopyToClipboard text={`${process.env.REACT_APP_URL}/blog/article/${id}`}>
                        <button
                            className={`button is-fullwidth ${clickedCopy ? 'is-success' : 'is-success is-outlined'}`}
                            onClick={() => {
                                setClickedCopy(!clickedCopy);
                                copyButtonRef.blur();
                            }}
                            ref={setCopyButtonRef}
                        >
                            <span className="icon">
                                <i className={`fas ${clickedCopy ? 'fa-check' : 'fa-share-square'}`}/>
                            </span>
                            <span>{clickedCopy ? 'Done !' : 'Copy link'}</span>
                        </button>
                    </CopyToClipboard>
                </div>

                <div className="card-footer-item">
                    <button
                        className={`button is-fullwidth ${clickedLike ? 'is-success' : 'is-success is-outlined'}`}
                        onClick={() => {
                            setClickedLike(!clickedLike);
                            likeButtonRef.blur()
                        }}
                        ref={setLikeButtonRef}
                    >
                        <span className="icon">
                            <i className="far fa-thumbs-up"/>
                        </span>
                        <span>Like this Article</span>
                    </button>
                </div>

                <PermissionFilter allowedRoles={[Roles.Author]}>
                    <div className="card-footer-item is-hidden-mobile">
                        <Link
                            className="button is-fullwidth is-success is-outlined"
                            to={`/admin/articles/edit/${id}`}
                        >
                            <span className="icon">
                                <i className="fas fa-edit"/>
                            </span>
                            <span>Edit</span>
                        </Link>
                    </div>
                </PermissionFilter>
            </footer>
        </div>
    )
};

const mapStateToProps = (state: State) => ({
    isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps, null)(ArticleFooter);
