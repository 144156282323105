import React from "react";
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { history } from '../stores/store';

// Organisms
import Header from '../elements/organisms/Header';
import Footer from "../elements/organisms/Footer";
// Pages
import GamePage from '../elements/pages/Games';
import Home from '../elements/pages/Home';
import Blog from "../elements/pages/Blog";
import Others from "../elements/pages/Others";
import OmrArticlePage from "../elements/pages/Others/OmrArticlePage";
import HigherCookery from "../elements/pages/Games/highercookery";
import Login from "../elements/pages/Login";
import {PrivatePage} from "./PrivateRoute";
import Admin from "../elements/pages/Admin";
import WriteArticlePage from "../elements/pages/Admin/WriteArticlePage";
import EditArticlePage from "../elements/pages/Admin/EditArticlePage";
import ReadArticlePage from "../elements/pages/Blog/ReadArticlePage";
import NotFound from "../elements/pages/NotFound";
import UsersPage from "../elements/pages/Admin/UsersPage";
import NewUserPage from "../elements/pages/Admin/NewUserPage";
import ArticlesPage from "../elements/pages/Admin/ArticlesPage";
import UrlShorten from "../elements/pages/Others/UrlShorten";
import NetMask from "../elements/pages/Others/NetMask";

const routes = (
    <ConnectedRouter history={history}>
        <Switch>
            <Route path={['/http:', '/https:']} component={(props: any) => {
                // This is a quick hack for being able to use the react-router <Link /> component
                // to link towards external links. It works pretty well without much effort !s

                window.location.replace(props.location.pathname.substr(1)); // substr(1) removes the preceding '/'
                return null;
            }}/>
            <Route exact path={["/home", "/"]}  component={Home} />
            <Route path="/login" exact component={Login} />
            <>
                <Header />
                <Route exact path="/games" component={GamePage} />
                <Route exact path="/games/highercookery"  component={HigherCookery} />
                <Route exact path="/blog"  component={Blog} />
                <Route exact path="/blog/article/:id"  component={ReadArticlePage} />
                <Route exact path="/others"  component={Others} />
                <Route exact path="/others/omr-article"  component={OmrArticlePage} />
                <Route exact path="/others/url-shorten"  component={UrlShorten} />
                <Route exact path="/others/netmask"  component={NetMask} />
                <Route exact path="/admin"  component={PrivatePage(Admin)} />
                <Route exact path="/admin/articles/edit/:id"  component={PrivatePage(EditArticlePage)} />
                <Route exact path="/admin/articles"  component={PrivatePage(ArticlesPage)} />
                <Route exact path="/admin/articles/new"  component={PrivatePage(WriteArticlePage)} />
                <Route exact path="/admin/users"  component={PrivatePage(UsersPage)} />
                <Route exact path="/admin/users/new"  component={PrivatePage(NewUserPage)} />
                <Route exact path="/404" component={NotFound} />
                <Footer />
            </>
        </Switch>
    </ConnectedRouter>
);

export default routes;
