import React from "react";
import { Link } from "react-router-dom";

type Props = {
    title?: string;
    image?: string;
    url?: string;
}

const GameCard: React.FC<Props> = ({image, url, children}) => (
    <div className="card is-info">
        {
            (image) ?
                <div className="card-image">
                    <Link to={url || "/404"} className="image">
                        <img src={image} alt={url} />
                    </Link>
                </div> : null
        }
        <div className="card-content">
            <div className="content content-of-card">
                {children}
            </div>
        </div>
    </div>
);

export default GameCard;
