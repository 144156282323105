import React, {useEffect} from "react";
import {connect} from "react-redux";
import ReactGA from "react-ga";

import {State} from "../../../reducers";
import {selectCurrentUser} from "../../../selectors/auth";
import {logout} from "../../../actions/auth";
import {Roles, User} from "commons";
import {Link} from "react-router-dom";
import Hero from "../../atoms/Hero";
import InfoTiles from "../../molecules/InfoTiles";
import AdminWrapper from "../../wrappers/AdminWrapper";
import PermissionFilter from "../../wrappers/PermissionFilter";

import "./index.scss"

type Props = {
    user?: User;
    logout: typeof logout;
}

const Admin: React.FC<Props> = ({ user, logout }) => {
    useEffect(() => ReactGA.pageview('/admin'));

    return (
        <AdminWrapper>
            <Hero
                title={`Hello there ${user ? user.firstName : ""} !`}
                subtitle="Latest news from Lotus Gears 🚀"
                specialClasses="is-bold is-small"
            />
            <InfoTiles/>
            <div className="level">
                <div className="level-item">
                    <Link to="/admin/articles" className="button is-success is-large">Articles 📚</Link>
                </div>
                <PermissionFilter allowedRoles={[Roles.SuperUser]}>
                    <div className="level-item">
                        <Link to="/admin/users" className="button is-success is-large">Users 👨🏼‍💻</Link>
                    </div>
                </PermissionFilter>
                <div className="level-item">
                    <button className="button is-success is-large" onClick={logout}>Log out 🦞</button>
                </div>
            </div>
            <div className="columns">
                <div className="column is-6">
                    <div className="card events-card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Events
                            </p>
                            <a href="#" className="card-header-icon" aria-label="more options">
                          <span className="icon">
                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                          </span>
                            </a>
                        </header>
                        <div className="card-table">
                            <div className="content">
                                <table className="table is-fullwidth is-striped">
                                    <tbody>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "5%"}}>
                                            <i className="fa fa-bell-o"/>
                                        </td>
                                        <td>Lorum ipsum dolem aire</td>
                                        <td className="level-right"><a className="button is-small is-primary"
                                                                       href="#">Action</a></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <footer className="card-footer">
                            <a href="#" className="card-footer-item">View All</a>
                        </footer>
                    </div>
                </div>
                <div className="column is-6">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                Inventory Search
                            </p>
                            <a href="#" className="card-header-icon" aria-label="more options">
                              <span className="icon">
                                <i className="fa fa-angle-down" aria-hidden="true"/>
                              </span>
                            </a>
                        </header>
                        <div className="card-content">
                            <div className="content">
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input is-large" type="text" placeholder=""/>
                                    <span className="icon is-medium is-left">
                                      <i className="fa fa-search"/>
                                    </span>
                                    <span className="icon is-medium is-right">
                                      <i className="fa fa-check"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                User Search
                            </p>
                            <a href="#" className="card-header-icon" aria-label="more options">
                              <span className="icon">
                                <i className="fa fa-angle-down" aria-hidden="true"/>
                              </span>
                            </a>
                        </header>
                        <div className="card-content">
                            <div className="content">
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input is-large" type="text" placeholder=""/>
                                    <span className="icon is-medium is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                    <span className="icon is-medium is-right">
                                        <i className="fa fa-check"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    );
}

const mapStateToProps = (state: State) => ({
    user: selectCurrentUser(state),
});

const mapDispatchToProps = {
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
