import ReactGA from "react-ga";
import { takeLatest, call } from 'redux-saga/effects'
import { Action } from "redux";

import { types as articlesTypes } from "../actions/articles";
import { types as articleTypes } from "../actions/article";
import { types as authTypes } from "../actions/auth";
import { types as usersTypes } from "../actions/users";
import { types as shortenTypes } from "../actions/shorten";

function* trackEvent(action: Action) {
    yield call(ReactGA.event, {
        category: 'user',
        action: action.type
    })
}

function* analyticsSaga() {
    yield takeLatest(authTypes.LOGIN_SUCCESS, trackEvent);

    yield takeLatest(articleTypes.UPDATE_ARTICLE, trackEvent);
    yield takeLatest(articleTypes.DELETE_ARTICLE, trackEvent);
    yield takeLatest(articlesTypes.SUBMIT_ARTICLE, trackEvent);

    yield takeLatest(usersTypes.CREATE_USER, trackEvent);

    yield takeLatest(shortenTypes.SHORTEN, trackEvent);
}

export default analyticsSaga;
