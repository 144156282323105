import React from "react";
import Markdown from "react-markdown";

import "./index.scss"
import Card from "../../molecules/Card";
import {humanFormatFullDate} from "../../../services/datetime";
import HighlightedCode from "../../molecules/HighlightedCode";
import ArticleFooter from "../../molecules/ArticleFooter";
import FloatingImage from "../../molecules/FloatingImage";

const htmlParser = require('react-markdown/plugins/html-parser');

// See https://github.com/aknuds1/html-to-react#with-custom-processing-instructions
// for more info on the processing instructions
const allowedHtmlTags = ['br'];
export const markdownHtmlParser = htmlParser({
    // Allow breaking tags for line breaks
    isValidNode: (node: any) => allowedHtmlTags.includes(node.name),
});

type Props = {
    text: string;
    id?: string;
    date?: Date;
    img_url?: string;
    side?: boolean;
}

const MarkdownArticle: React.FC<Props> = ({text, id, date, side, img_url}) => (
    <>
        <Card imageOnRight={side} imageOnLeft={!side} image={img_url}>
            <Markdown source={text}
                      escapeHtml={false}
                      astPlugins={[markdownHtmlParser]}
                      renderers={{
                          code: HighlightedCode,
                          image: FloatingImage,
                      }}
            />
            { date ?
                <p className="right-aligned">
                    <code ><time dateTime={date.toLocaleString()} >{humanFormatFullDate(date)}</time></code>
                </p>
                : null
            }
        </Card>
        {
            id ? <ArticleFooter id={id}/> : null
        }
    </>
);

export default MarkdownArticle;
