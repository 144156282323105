import MarkdownArticle from "../MarkdownArticle";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Article } from "commons";

import { updateArticle, deleteArticle } from "../../../actions/article";
import {State} from "../../../reducers";

type Props = {
    article: Article;
    isLoading: boolean;
    hasErrored: boolean;
    updateArticle: typeof updateArticle;
    deleteArticle: typeof deleteArticle;
}

const ArticleEditor: React.FC<Props> = ({ article, isLoading, hasErrored, updateArticle, deleteArticle }) => {
    const [value, setValue] = useState(article.text);
    const [img_url, setUrl] = useState(article.img_url);
    const [clickedUpdate, setClickedUpdate] = useState(false);
    const [clickedDel, setClickedDel] = useState(false);

    const submit = () => {
        updateArticle({
            ...article,
            img_url,
            text: value,
        });
        setClickedUpdate(true);
    };

    const delArticle = () => {
        deleteArticle(article.id);
        setClickedDel(true);
    };

    const isSuccessUpdate = !isLoading && !hasErrored && clickedUpdate;
    const isSuccessDel = !isLoading && !hasErrored && clickedDel;

    return (
        <div className="container">
            <h2 className="subtitle">Editing an article ! Article number </h2>
            <div className="field">
                <div className="control has-padding" id="article-writing">
                        <textarea
                            className="textarea is-success"
                            placeholder="I'm john, I like trains"
                            value={value}
                            onChange={event => setValue(event.target.value)}
                        />
                </div>
            </div>
            <div className="submit-section">
                <h2 className="subtitle">Preview the article, submit when ready ! 🚀</h2>
                <div className="is-flex is-fullwidth">
                    <input className="input" type="text" value={img_url} onChange={event => setUrl(event.target.value)} placeholder="http://image_for_article.com/image.png" />
                    <div className="field has-addons">
                        <p className="control">
                            <button className={`button is-success ${clickedUpdate && isLoading ? 'is-loading' : null}`} onClick={ submit }>{
                                isSuccessUpdate ? "Done ! Redirecting..." : <>
                                    <span className="icon">
                                        <i className="fas fa-edit" />
                                    </span>
                                    <span>Update the article</span>
                                </>
                            } </button>
                        </p>
                        <p className="control">
                            <button className={`button is-danger ${clickedDel && isLoading ? 'is-loading' : null}`} onClick={ delArticle }>{
                                isSuccessDel ? "Done ! Redirecting..." : <>
                                    <span className="icon">
                                        <i className="fas fa-exclamation-triangle"/>
                                    </span>
                                    <span>Delete the article</span>
                                </>
                            } </button>
                        </p>
                    </div>
                </div>
            </div>
            {
                hasErrored && <div className="notification is-danger">
                    Something went wrong while updating the article. Please try again later !
                </div>
            }
            {
                value && value.length > 0 ?
                    <div className="field has-border-left md-render">
                        <MarkdownArticle text={value}/>
                    </div> : null
            }
        </div>
    )
};

const mapStateToProps = (state: State) => ({
    isLoading: state.articles.loading,
    hasErrored: state.articles.error,
});

const mapDispatchToProps = {
    updateArticle,
    deleteArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditor);
