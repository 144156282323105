import { RouterState, connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { History } from 'history';

import auth, { AuthState } from './auth';
import articles, {ArticlesState} from "./articles";
import users, {UsersState} from "./users";
import roles, {RolesState} from "./roles";
import shorten, {ShortenState} from "./shorten";

export type State = {
    auth: AuthState;
    articles: ArticlesState;
    users: UsersState;
    roles: RolesState;
    router: RouterState;
    shorten: ShortenState;
};

export default (history: History) =>
  combineReducers<State | undefined>({
      router: connectRouter(history),
      auth,
      articles,
      users,
      roles,
      shorten,
  });
