import React from "react";

const Footer: React.FC = () => (
    <footer className="footer">
        <div className="hero is-small">
            <div
                className="hero-body is-flex-tablet"
                style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
                <span className="has-text-left">
                    © Pierre Averous
                    <br />
                </span>

                <span className="has-text-centered">
                    Designed with <a href="https://bulma.io/"><strong>Bulma</strong></a>
                    <br />Hosted with <i className="fas fa-heart"></i> on <a href="https://gitlab.com/"><strong>Gitlab</strong></a>
                    <br />Photos from <a href="https://pexels.com/"><strong>Pexels</strong></a>
                    <br />

                </span>

                <span className="has-text-right">
                    <span className="icon is-medium">
                        <a href="https://www.instagram.com/paverous/"><i className="fab fa-lg fa-instagram"></i></a>
                    </span>
                    <span className="icon is-medium">
                        <a href="https://github.com/piaverous/"><i className="fab fa-lg fa-github-alt"></i></a>
                    </span>
                    <span className="icon is-medium">
                        <a href="https://gitlab.com/piaverous/"><i className="fab fa-lg fa-gitlab"></i></a>
                    </span>
                    <span className="icon is-medium">
                        <a href="https://www.linkedin.com/in/pierre-averous/"><i className="fab fa-lg fa-linkedin-in"></i></a>
                    </span>
                </span>
            </div>
        </div>
    </footer>
)

export default Footer;