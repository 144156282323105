import { Action, ActionCreator } from 'redux';
import { Article } from "commons"

export const types = {
    SUBMIT_ARTICLE: 'SUBMIT_ARTICLE',
    SUBMIT_ARTICLE_SUCCESS: 'SUBMIT_ARTICLE_SUCCESS',
    SUBMIT_ARTICLE_FAILURE: 'SUBMIT_ARTICLE_FAILURE',

    GET_ARTICLES: 'GET_ARTICLES',
    GET_ARTICLES_SUCCESS: 'GET_ARTICLES_SUCCESS',
    GET_ARTICLES_FAILURE: 'GET_ARTICLES_FAILURE',
};

export interface SubmitArticle extends Action {
    type: String;
    payload: Omit<Article, "id">;
}
export interface SubmitArticleSuccess extends Action {
    type: String;
}
export interface SubmitArticleFailure extends Action {
    type: String;
}

export interface GetArticles extends Action {
    type: String;
}
export interface GetArticlesSuccess extends Action {
    type: String;
    articles: Article[];
}
export interface GetArticlesFailure extends Action {
    type: String;
}

export const submitArticle: ActionCreator<SubmitArticle> = (text: string, author_id: string, img_url?: string) => ({
    type: types.SUBMIT_ARTICLE,
    payload: {
        text,
        date: new Date(),
        img_url,
        author_id,
    },
});
export const submitArticleSuccess: ActionCreator<SubmitArticleSuccess> = () => ({
    type: types.SUBMIT_ARTICLE_SUCCESS,
});
export const submitArticleFailure: ActionCreator<SubmitArticleFailure> = () => ({
    type: types.SUBMIT_ARTICLE_FAILURE,
});


export const getArticles: ActionCreator<GetArticles> = () => ({
    type: types.GET_ARTICLES,
});
export const getArticlesSuccess: ActionCreator<GetArticlesSuccess> = (articles: Article[]) => ({
    type: types.GET_ARTICLES_SUCCESS,
    articles,
});
export const getArticlesFailure: ActionCreator<GetArticlesFailure> = () => ({
    type: types.GET_ARTICLES_FAILURE,
});
