import React, {useEffect} from 'react';
import ReactGA from "react-ga";
// @ts-ignore
import Fade from "react-reveal";
import "./index.scss"

import logo from "../../../assets/images/Logo/LogoWhite.png";
// Elements
import Title from '../../atoms/Title';
import Subtitle from '../../atoms/Subtitle';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  useEffect(() => ReactGA.pageview('/home'));

  return (
      <div id="home">
        <div id="home-sup">
          <div className="is-flex-desktop has-text-centered-mobile" style={{justifyContent: "space-around"}} >
            <div id="home-top-section-wrapper">
              <Title text="Lotus Gears"/>
              <Subtitle text="Dev work, all kinds of experiences that I believe are worth sharing :)"/>

              <Fade left>
                <Link className="button" to="/blog">
                  <span>Continue to site</span>
                  <span className="icon is-small">
                <i className="far fa-arrow-alt-circle-right" />
              </span>
                </Link>
              </Fade>
            </div>
            <div>
              <img className="image" src={logo} id="spin-logo" alt="Logo" style={{width: "15rem"}}/>
            </div>
          </div>
        </div>

      </div>
  );
}

export default Home;
