import React from "react";

import './index.scss';
import ImageSkeleton from "../../atoms/ImageSkeleton";
import ArticleSkeleton from "../../molecules/ArticleSkeleton";

type Props = {
    imageOnRight?: boolean;
    imageOnLeft?: boolean;
}

const CardSkeleton: React.FC<Props> = ({
    imageOnRight,
    imageOnLeft,
}) => (
    <div className="card is-info">
        <div className="columns" style={{ padding: "1rem" }}>
            {
                imageOnLeft ?
                    <div className="column">
                        <ImageSkeleton />
                    </div> : null
            }
            <div className="column">
                <div className={`card-content ${imageOnRight || imageOnLeft ? "is-paddingless" : ""}`}>
                    <div className="content content-of-card">
                        <ArticleSkeleton />
                    </div>
                </div>
            </div>
            {
                imageOnRight ?
                    <div className="column">
                        <ImageSkeleton />
                    </div>  : null
            }
        </div>

    </div>
)

export default CardSkeleton;
