import { Article } from 'commons';
import { types } from '../actions/articles';
import { types as articleTypes } from '../actions/article';

export type ArticlesState = {
    articles?: Article[];
    loading: boolean;
    error: boolean;
};

const initialState = {
    loading: false,
    error: false,
};

const articlesReducer = (state: ArticlesState = initialState, action: any): ArticlesState => {
    switch (action.type) {
        case types.GET_ARTICLES:
        case types.SUBMIT_ARTICLE:
        case articleTypes.GET_ARTICLE:
        case articleTypes.DELETE_ARTICLE:
        case articleTypes.UPDATE_ARTICLE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case types.GET_ARTICLES_FAILURE:
        case types.SUBMIT_ARTICLE_FAILURE:
        case articleTypes.GET_ARTICLE_FAILURE:
        case articleTypes.UPDATE_ARTICLE_FAILURE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case types.SUBMIT_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case types.GET_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: action.articles.map((article: Article) => ({
                    ...article,
                    date: new Date(article.date),
                })),
                loading: false,
                error: false,
            };
        case articleTypes.GET_ARTICLE_SUCCESS:
            const article = {
                ...action.article,
                date: new Date(action.article.date),
            };
            return {
                ...state,
                articles: state.articles ? [...state.articles, article] : [ article ],
                loading: false,
                error: false,
            };
        case articleTypes.UPDATE_ARTICLE_SUCCESS:
            const updateArticle = {
                ...action.article,
                date: new Date(action.article.date),
            };
            return {
                ...state,
                articles: state.articles ? [...state.articles, updateArticle] : [ updateArticle ],
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};

export default articlesReducer;
