import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router";

import { State } from '../reducers';
import { getToken } from '../selectors/auth';
import { isTokenExpired } from '../services/auth';
import { logout } from "../actions/auth";

export function PrivatePage (Component: any) {
  class PrivateComponent extends React.Component<any> {
    renderPage() {
      return (
        <Component {...this.props} />
      )
    }

    render() {
      const token = this.props.token;
      if (token && ! isTokenExpired(token)) {
        return this.renderPage()
      } else {
        this.props.logout();
        return <Redirect to='/login'/>
      }
    }
  }

  const mapStateToProps = (state: State) => ({
    token: getToken(state),
  });

  const mapDispatchToProps = {
    logout
  };

  return connect(mapStateToProps, mapDispatchToProps)(PrivateComponent)
}

