import React, {useState} from "react";
import {connect} from "react-redux";
import "./index.scss"
import { User } from "commons";

import {State} from "../../../../reducers";
import {submitArticle} from "../../../../actions/articles";
import {selectCurrentUser} from "../../../../selectors/auth";
import MarkdownArticle from "../../../organisms/MarkdownArticle";
import AdminWrapper from "../../../wrappers/AdminWrapper";

type Props = {
    submitArticle: typeof submitArticle;
    isLoading: boolean;
    hasErrored: boolean;
    user?: User;
}

const WriteArticlePage: React.FC<Props> = ({
    submitArticle,
    isLoading,
    hasErrored,
    user,
}) => {
    const [value, setValue] = useState("");
    const [img_url, setUrl] = useState("");
    const [clicked, setClicked] = useState(false);

    const submit = () => {
        if ( user && value) {
            submitArticle(value, user.id, img_url || undefined);
            setClicked(true);
        }
    };
    const isSuccess = !isLoading && !hasErrored && clicked;
    return (
        <AdminWrapper>
            <h2 className="subtitle">Write an article ! Markdown is kinda supported :)</h2>
            <div className="field">
                <div className="control has-padding" id="article-writing">
                    <textarea
                        className="textarea is-success"
                        placeholder="I'm john, I like trains"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                    />
                </div>
            </div>
            <div className="submit-section">
                <h2 className="subtitle">Preview the article, submit when ready ! 🚀</h2>
                <div className="is-flex is-fullwidth">
                    <input className="input" type="text" value={img_url} onChange={event => setUrl(event.target.value)} placeholder="http://image_for_article.com/image.png" />
                    <button className={`button is-success ${isLoading ? 'is-loading' : null}`} onClick={ submit }>{
                        isSuccess ? "Done ! Redirecting..." : <>
                            <span className="icon">
                                <i className="fas fa-upload" />
                            </span>
                            <span>Submit</span>
                        </>
                    }</button>
                </div>
            </div>
            {
                hasErrored && <div className="notification is-danger">
                    Something went wrong while submitting the article. Please try again later !
                </div>
            }
            {
                value && value.length > 0 ?
                    <div className="field has-border-left md-render">
                        <MarkdownArticle text={value}/>
                    </div> : null
            }
        </AdminWrapper>
    )
};

const mapStateToProps = (state: State) => ({
    isLoading: state.articles.loading,
    hasErrored: state.articles.error,
    user: selectCurrentUser(state),
});

const mapDispatchToProps = {
    submitArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(WriteArticlePage);
