import { Action, ActionCreator } from 'redux';
import {RolesByUser} from "../reducers/roles";

export const types = {
    GET_ROLES: 'GET_ROLES',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',
};

export interface GetRoles extends Action {
    type: String;
    userIds: string[]
}
export interface GetRolesSuccess extends Action {
    type: String;
    roles: RolesByUser;
}
export interface GetRolesFailure extends Action {
    type: String;
}


export const getRoles: ActionCreator<GetRoles> = (userIds: string[]) => ({
    type: types.GET_ROLES,
    userIds
});
export const getRolesSuccess: ActionCreator<GetRolesSuccess> = (roles: RolesByUser) => ({
    type: types.GET_ROLES_SUCCESS,
    roles,
});
export const getRolesFailure: ActionCreator<GetRolesFailure> = () => ({
    type: types.GET_ROLES_FAILURE,
});
