import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import createSagaMiddleware from 'redux-saga';

import { createBrowserHistory } from 'history';

import createRootReducer, {State} from '../reducers';
import rootSaga from "../sagas";
import {types as authTypes} from '../actions/auth';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export function redirectTo(location: string) {
    history.push(location);
}

const routerMiddlerware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routerMiddlerware];

const appReducer = createRootReducer(history);
const rootReducer = (state: State | undefined, action: any) => {
    if (action.type === authTypes.LOGOUT) {
        state = undefined
    }
    return appReducer(state, action)
};

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
        whitelist: ['auth'],
    },
    rootReducer
);

const composeEnhancers =
  process.env.NODE_ENV === 'test' ? composeWithDevTools : composeWithDevTools;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export default {
  store,
  persistor,
};
