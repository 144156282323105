import React from 'react';

type ImageProps = {
    src?: string;
    alt?: string;
}

const FloatingImage: React.FC<ImageProps> = ( {src, alt} ) =>{
    let float: "right" | "left" | undefined = "right";
    let width = "40%";
    let className = "is-hidden-touch";
    let finalAlt = alt;
    if (alt) {
        const instructions = alt.split(" ");
        if (instructions.length >= 2) {
            finalAlt = instructions.slice(0, instructions.length-1).join(" ");
            switch (instructions[instructions.length - 1]) {
                case "<":
                    float = "left";
                    break;
                case ">":
                    float = "right";
                    break;
                case "_":
                    float = undefined;
                    width = "75%";
                    className = "has-text-centered";
                    break;
                default:
                    break;
            }
        }
    }
    return <p className={className}>
        <img src={src} style={{float, width}} alt={finalAlt} />
    </p>
} ;

export default FloatingImage;
