import React from "react";
import axios from "axios";
import Unity, { UnityContent } from "react-unity-webgl";

import "./index.scss";
import Title from "../../../atoms/Title";
// import SmallLoader from "../../../molecules/SmallLoader";
import BigLoader from "../../../molecules/BigLoader";

type Score = {
    username: string;
    score: number;
}

type ScoreSet = {
    [key: number]: Score[],
}

type ScoreEntry = {
    id: string;
    user_id: string;

    level: number;
    score: number;
    username: string;    
}

type State = {
    scores: ScoreSet;
    selectedLevel: number;
    loading: boolean;
}

class HigherCookery extends React.Component<any, State> {
    timer: NodeJS.Timeout | null;
    unityContent?: UnityContent;

    constructor(props: any) {
        super(props);
        this.state = { 
            scores: {
                1: [],
                2: [],
                3: []
            },
            selectedLevel: 1,
            loading: true,
        };

        this.timer = setInterval(() => {
            this.timer && this.updateAllLevels();
        }, 5000)
    }

    updateForLevel = async (level: number) => {
        const response = await axios.get("https://higher-cookery.herokuapp.com/leaderboard/"+level)
        const leaderboard: Score[] = response.data.leaderboard;

        this.setState({scores: {...this.state.scores, [level]: leaderboard} })
    }

    updateAllLevels = async () => {
        const response = await axios.get("https://higher-cookery.herokuapp.com/leaderboard")
        const leaderboard: ScoreEntry[] = response.data.leaderboard;

        const scores: ScoreSet = leaderboard.reduce((map, entry) => {
            if(map[entry.level]) {
                map[entry.level].push(entry)
            }
            else {
                map[entry.level] = [entry];
            }
            return map;
        }, {} as ScoreSet)
        this.setState({ scores })
    }

    /* eslint-disable */
    renderTab = (level: number) => (
        <li className={"tab " + (this.state.selectedLevel === level ? "is-active": "")} key={level}>
            <a onClick={() => this.setState({selectedLevel: level})}>Level {level}</a>
        </li>
    )
    /* eslint-enable */

    componentWillUnmount = () => {
        this.unityContent = undefined;
        this.timer = null;
    }

    componentDidMount = () => {
        this.unityContent = new UnityContent(
            "/highercookery/built-game/higher-cookery.json",
            "/highercookery/built-game/UnityLoader.js"
        );

        setTimeout(() => this.setState({loading: false}), 1500)
        this.updateAllLevels();
    }

    render = () => (
        <>
            <section className="hero is-small">
                <div className="hero-body">
                    <div className="container">
                    <h1 className="title is-family-secondary">
                        Welcome to our HigherCookery !
                    </h1>
                    <h2 className="subtitle">
                        Compare your score with your friends ;)
                    </h2>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="is-hidden-touch">
                    {
                        this.unityContent && !this.state.loading ? 
                        <>
                            <Unity unityContent={this.unityContent} /> 
                            <button className="button" id="fullscreen-button" onClick={() => this.unityContent && this.unityContent.setFullscreen(true)}>
                                <span className="icon">
                                    <i className="fas fa-expand-arrows-alt" />
                                </span>
                            </button>
                        </>
                        : <BigLoader />
                    }
                </div>
                <div className="is-hidden-desktop">
                    <div className="notification is-danger">
                        Sorry, this game was built using Unity. It is not available in the browser on your mobile device.
                        <br />
                        <br />
                        In order to play, use a computer :)
                    </div>
                </div>
                {/* <div className="card">
                    <div className="tabs is-fullwidth is-marginless">
                        <ul>
                            {[1, 2, 3].map(this.renderTab)}
                        </ul>
                    </div>
                    <div className="card-content" id="scrollable-table">
                        <table className="table is-bordered is-striped is-fullwidth">
                            <thead id="sticky-table-header">
                                <tr>
                                    <th className="first-column">Rank</th>
                                    <th>User</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    this.state.scores[this.state.selectedLevel].length > 0 ? this.state.scores[this.state.selectedLevel].map((score, i) => (
                                        <tr key={i}>
                                            <td className="first-column">{i+1}</td>
                                            <td>{score.username}</td>
                                            <td>{score.score}</td>
                                        </tr>
                                    )) : <SmallLoader />
                                }
                            </tbody>
                        </table>
                    </div>
                </div> */}
                <div className="card has-margin">
                    <div className="card-content">
                        <Title text="Downloads"/>
                            
                        <div className="columns is-hidden-touch">
                            <div className="column is-paddingless has-margin-right">
                                <a href="https://gitlab.com/piaverous/pooa-overcooked/-/archive/master/pooa-overcooked-master.zip?path=Game%2FMac" className="button is-large is-info is-fullwidth">
                                    <span className="icon is-small">
                                        <i className="fab fa-apple"></i>
                                    </span>
                                    <span>MacOS (Intel) Download</span>
                                </a>
                            </div>
                            <div className="column is-paddingless">
                                <a href="https://gitlab.com/piaverous/pooa-overcooked/-/archive/master/pooa-overcooked-master.zip?path=Game%2FWindows" className="button is-large is-info is-fullwidth">
                                    <span className="icon is-small">
                                        <i className="fab fa-windows"></i>
                                    </span>
                                    <span>Windows Download</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="notification is-success">
                    <p>
                        Game built by {" "}
                        <strong><a href="https://gitlab.com/piaverous">
                            Pierre Averous
                        </a></strong>, {" "}
                        <strong><a href="https://gitlab.com/gouta">
                            Arthur Goutallier
                        </a></strong> and {" "}
                        <strong><a href="https://gitlab.com/nico_ajax">
                            Nicolas Topuz
                        </a></strong> {" "}
                        using <strong>Unity</strong>.
                    </p>
                    <p>
                        Special thanks to all out beta-testers listed {" "}
                        <strong><a href="https://gitlab.com/piaverous/pooa-overcooked#remerciements">
                            here
                        </a></strong> !
                    </p>
                </div>
            </div>
        </>
    )
}


export default HigherCookery;
