import React, {useState} from "react";
import {Link} from "react-router-dom";

import Title from "../../../atoms/Title";
import Subtitle from "../../../atoms/Subtitle";

import netmask from "../../../../services/netmask";

const submit = (address: string, cidr: string) => {
    const split_address = address.split(".");
    if (split_address.length !== 4) {
        throw Error("Could not parse address")
    }
    return netmask(split_address.map(num => parseInt(num)), parseInt(cidr))
}

const NetMask: React.FC = () => {
    const [address, setAddress] = useState("");
    const [cidr, setCidr] = useState("");
    const [result, setResult] = useState();

    const send = (address: string, cidr: string) => setResult(submit(address, cidr));

    return (
        <div className="container">
            <Link className="button is-success is-outlined" to="/others">
                <span className="icon">
                    <i className="fas fa-arrow-left"/>
                </span>
                <span>Back to "Others"</span>
            </Link>
            <div className="card is-info">
                <div className="columns" style={{ padding: "1rem" }}>
                    <div className="column">
                        <Title text="IP Subnetting"/>
                        <Subtitle text="...get hosts in your Subnet !"/>
                        <form className="field has-addons" onSubmit={(e) => {
                            e.preventDefault();
                            send(address, cidr);
                        }}>
                            <div className="control is-expanded">
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="IP: 192.168.0.1"
                                    value={address}
                                    onChange={event => setAddress(event.target.value)}
                                />
                            </div>
                            <div className="control is-expanded">
                                <input
                                    className="input"
                                    type="number"
                                    placeholder="CIDR subnet mask: 24"
                                    value={cidr}
                                    onChange={event => setCidr(event.target.value)}
                                />
                            </div>
                            <div className="control">
                                <button className="button is-info" onClick={() => send(address, cidr)}>
                                    See Result !
                                </button>
                            </div>
                        </form>
                        { result ?
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <tr>
                                    <th>Address</th>
                                    <td>{address}</td>
                                </tr>
                                <tr>
                                    <th>Subnet Mask</th>
                                    <td>{result.mask}</td>
                                </tr>
                                <tr>
                                    <th>Subnet first address</th>
                                    <td>{result.first}</td>
                                </tr>
                                <tr>
                                    <th>Subnet last address</th>
                                    <td>{result.last}</td>
                                </tr>
                            </table> : 
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                <tr>
                                    <th>Address</th>
                                    <td>ip address</td>
                                </tr>
                                <tr>
                                    <th>Subnet Mask</th>
                                    <td>subnet mask</td>
                                </tr>
                                <tr>
                                    <th>Subnet 1st address</th>
                                    <td>subnet start</td>
                                </tr>
                                <tr>
                                    <th>Subnet last address</th>
                                    <td>subnet end</td>
                                </tr>
                            </table>
                        }
                    </div>
                    <div className="column">
                        <a href="https://www.pexels.com/fr-fr/photo/affichage-afficher-brillant-briller-1735658/">
                            <figure className="image">
                                <img src="https://images.pexels.com/photos/1735658/pexels-photo-1735658.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="Pexels ohlala"/>
                            </figure>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NetMask;
