import { types as shortenTypes } from '../actions/shorten';

export type ShortenState = {
    url?: string;
    loading: boolean;
    error: boolean;
};

const initialState = {
    loading: false,
    error: false,
};

const shortenReducer = (state: ShortenState = initialState, action: any): ShortenState => {
    switch (action.type) {
        case shortenTypes.SHORTEN:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case shortenTypes.SHORTEN_FAILURE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case shortenTypes.SHORTEN_SUCCESS:
            return {
                ...state,
                url: action.payload.url,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};

export default shortenReducer;
