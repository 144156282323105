
const months = ['Jan.', 'Feb.', 'Mars', 'Avril', 'Mai', 'Juin', 'Jul.', 'Aout', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

export const humanFormatFullDate = (date: Date) => {
    const h = date.getHours();
    const hours = h >= 10 ? h : `0${h}`;

    const m = date.getMinutes();
    const minutes = m >= 10 ? m : `0${m}`;

    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}, ${hours}:${minutes}`
};
