import React, {useEffect} from "react";
import {Article, Roles} from "commons";
import {getArticles} from "../../../../actions/articles";
import AdminWrapper from "../../../wrappers/AdminWrapper";
import Hero from "../../../atoms/Hero";
import {selectArticles} from "../../../../selectors/articles";
import {State} from "../../../../reducers";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PermissionFilter from "../../../wrappers/PermissionFilter";
import {deleteArticle} from "../../../../actions/article";

type Props = {
    articles?: Article[];
    getArticles: typeof getArticles;
    deleteArticle: typeof deleteArticle;
    isLoading: boolean;
    hasErrored: boolean;
}
const ArticlesPage: React.FC<Props> = ({
    articles,
    getArticles,
    deleteArticle,
    isLoading,
    hasErrored
}) => {
    useEffect(() => {
        getArticles();
    }, [getArticles]);

    const handleDelete = (articleId: string) => {
        const confirmed = window.confirm("Are you sure you want to delete an article ?")
        if (confirmed) {
            deleteArticle(articleId)
        }
    };
    return (
        <AdminWrapper>
            <Hero
                title="Articles"
                subtitle="Manage articles here ‍📚"
                specialClasses="is-bold is-small"
            />
            <div className="has-text-right above-table">
                <PermissionFilter allowedRoles={[Roles.Author]}>
                    <Link to="/admin/articles/new" className="button is-success">
                        <span className="icon is-small">
                            <i className="fas fa-user-plus"/>
                        </span>
                        <span>New Article</span>
                    </Link>
                </PermissionFilter>
            </div>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Author ID</th>
                    <th>Date</th>
                    <th>Preview</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {articles && articles.map(article => (
                    <tr>
                        <td>{`${article.id.substr(0, 20)}...`}</td>
                        <td>{`${article.author_id.substr(0, 20)}...`}</td>
                        <td>{article.date.toDateString()}</td>
                        <td>{`${article.text.substr(0, 20)}...`}</td>
                        <td>
                            <PermissionFilter allowedRoles={[Roles.Author]}>
                                <Link to={`/admin/articles/edit/${article.id}`} className="icon is-small has-text-success">
                                    <i className="fas fa-edit"/>
                                </Link>
                            </PermissionFilter>
                        </td>
                        <td>
                            <PermissionFilter allowedRoles={[Roles.Author]}>
                                <a onClick={() => handleDelete(article.id)} className="icon is-small has-text-danger">
                                    <i className="fas fa-times"/>
                                </a>
                            </PermissionFilter>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {
                hasErrored && <div className="notification is-danger">
                    Something went wrong while fetching the articles... Please try again later !
                </div>
            }

        </AdminWrapper>
    );
};

const mapStateToProps = (state: State) => ({
    articles: selectArticles(state),
    isLoading: state.articles.loading,
    hasErrored: state.articles.error,
});

const mapDispatchToProps = {
    getArticles,
    deleteArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesPage);
