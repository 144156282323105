import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./index.scss"
import { User, Article } from "commons";
import { RouteComponentProps } from "react-router-dom";

import { State } from "../../../../reducers";
// Elements
import CardSkeleton from "../../../molecules/CardSkeleton";
// Selectors
import { selectCurrentUser } from "../../../../selectors/auth";
import { selectArticleById } from "../../../../selectors/articles";
// Actions
import { getArticle } from "../../../../actions/article";
import ArticleEditor from "../../../organisms/ArticleEditor";
import AdminWrapper from "../../../wrappers/AdminWrapper";

type Props = {
    getArticle: typeof getArticle;
    user?: User;
    article?: Article;
} & RouteComponentProps<{id: string}>

const EditArticlePage: React.FC<Props> = (props) => {
    useEffect(() => {
        if (!props.article) {
            props.getArticle(props.match.params.id);
        }
    }, [props]);

    return <AdminWrapper>
        { props.article ?
            <ArticleEditor article={props.article} />
            : <CardSkeleton/>
        }
    </AdminWrapper>;
};

const mapStateToProps = (state: State, ownProps: Props) => ({
    user: selectCurrentUser(state),
    article: selectArticleById(state, ownProps.match.params.id)
});

const mapDispatchToProps = {
    getArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditArticlePage);
