import React, {useEffect} from "react";
import {connect} from "react-redux";
import "./index.scss"
import { User } from "commons";

import {State} from "../../../../reducers";
import Hero from "../../../atoms/Hero";
import {selectUsers} from "../../../../selectors/users";
import {getUsers} from "../../../../actions/users";
import {Link} from "react-router-dom";
import AdminWrapper from "../../../wrappers/AdminWrapper";
import {selectRoles} from "../../../../selectors/roles";
import {getRoles} from "../../../../actions/roles";
import {RolesByUser} from "../../../../reducers/roles";

type Props = {
    users?: User[];
    roles?: RolesByUser;

    getUsers: typeof getUsers;
    isLoading: boolean;
    hasErrored: boolean;

    getRoles: typeof getRoles;

    user: User | null;
}

const UsersPage: React.FC<Props> = ({
    users,
    roles,
    isLoading,
    hasErrored,
    getUsers,
    getRoles,
}) => {
    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        users && getRoles(users.map(u => u.id));
    }, [getRoles, users]);

    return (
        <AdminWrapper>
            <Hero
                title="Users"
                subtitle="Manage users here 👨🏼‍💻"
                specialClasses="is-bold is-small"
            />

            <div className="has-text-right above-table">
                <Link to="/admin/users/new" className="button is-success">
                    <span className="icon is-small">
                        <i className="fas fa-user-plus"/>
                    </span>
                    <span>Add User</span>
                </Link>
            </div>
            <table className="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {users && users.map(user => (
                    <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{roles && roles[user.id] ? roles[user.id].role.role_name : '?'}</td>
                        <td>
                            <a className="icon is-small has-text-danger">
                              <i className="fas fa-times"></i>
                            </a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {
                hasErrored && <div className="notification is-danger">
                    Something went wrong while fetching the users... Please try again later !
                </div>
            }
        </AdminWrapper>
    )
};

const mapStateToProps = (state: State) => ({
    users: selectUsers(state),
    isLoading: state.users.loading,
    hasErrored: state.users.error,

    roles: selectRoles(state),
    rolesLoading: state.roles.loading,
    rolesErrored: state.roles.error,
});

const mapDispatchToProps = {
    getUsers,
    getRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
