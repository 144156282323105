import React, {useEffect} from 'react';
import ReactGA from "react-ga";
// Assets
import highercookery from "../../../assets/images/Games/highercookery.png"
import shootyarrow from "../../../assets/images/Games/shootyarrow.png"
// Elements
import GameCard from "../../molecules/GameCard";

const GamePage: React.FC = () => {
    useEffect(() => ReactGA.pageview('/games'));

    return (
        <div className="container">
            <div className="columns">
                <div className="column">
                    <GameCard image={highercookery} title="Higher Cookery" url="/games/highercookery">
                        A freakishly hard cooking game, <strong>very strongly </strong>
                        based on <a href="https://fr.wikipedia.org/wiki/Overcooked">Overcooked</a>. Built this in a
                        team project for a programming class.
                    </GameCard>
                </div>
                <div className="column">
                    <GameCard image={shootyarrow} title="Shooty Arrow" url="">
                        A simple shooter game, work in progress ! I am working
                        on it on my spare time, so it development is not very
                        fast, and I don't really have anywhere I want to go with
                        this project, but still ; it's a lot of fun to make !
                    </GameCard>
                </div>
            </div>
        </div>
    );
}

export default GamePage;
