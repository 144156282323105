import React, {useEffect} from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

import bulmaLogo from "../../../assets/images/Others/bulma-logo.png"
// import omrExample from "../../../assets/images/Others/omr_example.png"
import lotusGearsLogo from "../../../assets/images/Logo/LogoPrimary.png";

import "./index.scss";

const Others: React.FC = () => {
    useEffect(() => ReactGA.pageview('/others'));

    return (
        <div className="container">
            <div id="others-title">
                <p className="title">The <i>Others</i> section</p>
                <p className="subtitle">Randomness by design</p>
                <div className="content">
                    <p>The goal of this section is to show all kinds of random things, that I found interesting in some way at some point.</p>
                </div>
            </div>

            <div className="tile is-ancestor">
                <div className="tile is-parent">
                    <a href="https://bulma.io/" className="tile is-child box">

                        <p className="title"><span className="has-text-green">Bulma.io</span></p>
                        <p className="subtitle">An amazing css framework !</p>
                        <p>
                            Bulma is a free, open source CSS framework based on Flexbox. Check it out at <a href="https://bulma.io/">https://bulma.io/</a> !
                        </p>
                        <br/>
                        <img src={bulmaLogo} alt="Bulma logo"/>
                    </a>

                </div>
                {/* <div className="tile is-parent">
                    <Link to="/others/omr-article" className="tile is-child box">
                        <p className="title is-family-secondary">Optical Music Recognition</p>
                        <p className="subtitle">Or how to decypher musical scores</p>

                        <div className="content">
                            <p>
                                I recently decided to tackle the writing of an Optical Music Recognition (or OMR) algorithm.
                                Since I had no idea what i was doing, i had to read up a bit.
                                <br/>It was quite a fun project, check out my findings !
                            </p>
                            <img className="image" src={omrExample} alt="OMR"/>
                        </div>
                    </Link>
                </div> */}
                <div className="tile is-parent">
                    <a href="https://www.heroku.com/" className="tile is-child box">
                        <p className="title">Heroku</p>
                        <p className="subtitle">Free cloud deployments for your apps</p>
                        <div className="content">
                            <p>
                                Did you know this website's backend and Database were fully hosted on Heroku ? Quite amazing, right ?
                                Heroku is a cloud-based hosting service which has (to my knowledge) the best free plan
                                out there for devs.
                            </p>
                            <img className="image" src="https://www3.assets.heroku.com/assets/logo-purple-08fb38cebb99e3aac5202df018eb337c5be74d5214768c90a8198c97420e4201.svg" alt="OMR"/>
                        </div>
                    </a>
                </div>
            </div>
            <div className="tile is-ancestor">
                <div className="tile is-parent">
                    <Link to="/others/netmask" className="tile is-child box">
                        <p className="title is-family-secondary">IP Subnetting</p>
                        <p className="subtitle">What's in the subnet ?</p>
                        <div className="content">
                            <p>
                                A very simple tool to compute the IP address range of a subnet ! 
                                <br />
                                <br />
                                Very useful when building VPCs for example.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="tile is-parent">
                    <Link to="/others/url-shorten" className="tile is-child box">
                        <p className="title is-family-secondary">Lotus Gears</p>
                        <p className="subtitle">You're currently visiting this site !</p>

                        <div className="is-fullwidth centered-container">
                            <img className="image is-128x128 spinning" src={lotusGearsLogo} alt="Logo"/>
                        </div>
                    </Link>
                </div>
                <div className="tile is-parent">
                    <a href="https://datatables.net/" className="tile is-child box">
                        <p className="title">Datatables</p>
                        <p className="subtitle">Super fast advanced HTML tables</p>
                        <div className="content">
                            <p>
                                DataTables is a plug-in for the jQuery Javascript library. It is a highly flexible tool, allowing to paginate, query, sort the data in your table out of the box !
                                <br />
                                <br />
                                A great tool for prototyping something very quickly !
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Others;
